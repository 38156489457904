import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink, useNavigate } from 'react-router-dom'
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from "../firebase";

const Det = ({ user, paid, setPaid }) => {


    useEffect(() => {
        const fetchPaymentStatus = async () => {
            if (user) {
                const db = getFirestore();
                const userRef = doc(db, 'paying_users', user.email);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setPaid(userDoc.data().paid || false);
                }
            }
        };

        fetchPaymentStatus();
    }, [user]);



    const signInWithGoogle = async () => {
        try {
            await signInWithPopup(auth, googleProvider);
        } catch (error) {
            console.error("Error signing in with Google:", error);
        }
    };

    const navigate = useNavigate();

    const handleStartNow = () => {
        if (user && paid) {
            navigate("/test");
        } else if (!user) {
            signInWithGoogle();
        } else if (!paid) {
            navigate("/pricing");
        }
    };

    return (
        <div className="flex justify-center">
            <Helmet>
                <title>Ace the Duolingo English Test with GPT-4 and Whisper AI</title>
                <meta name="description" content="Access lectures, practice tests, and more to excel in the Duolingo English Test with the help of GPT-4 and Whisper AI." />
            </Helmet>
            <div className="w-5/6 grid grid-cols-1 sm:grid-cols-2">
                <div className="py-44 px-5 sm:px-16">
                    <p className="text-gray-800 font-semibold text-3xl">Access your free Duolingo English Test</p>
                    <p className="text-gray-600 mt-2 text-lg py-5">Get estimated scores across four sections: Literacy, Production, Conversation, and Comprehension. Take your first test for free.</p>
                    <div className="flex justify-center">
                        <button onClick={handleStartNow} className='px-5 py-2 rounded bg-blue-800 text-yellow-300 font-semibold'>
                            Start Now
                        </button>
                    </div>
                </div>
                <div className="flex justify-center items-center">
                    <img className="w-5/6 h-80 rounded" src="https://luya.blob.core.windows.net/edux/new2.svg" alt=''></img>
                </div>
            </div>
        </div>
    );
}

export default Det;