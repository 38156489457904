import React, { useState, useEffect, useRef } from "react"
import { FaMicrophone } from "react-icons/fa6";
import { IoStop } from "react-icons/io5";
import { RiSpeakLine } from "react-icons/ri";

const ListenThenSpeak = ({ data, handleNextSection, setListenSpeak }) => {

    const audioRef = useRef(null);

    const playAudio = () => {
        if (audioRef.current) {
            audioRef.current.play();
        }
    };


    const [current, setCurrent] = useState(0);
    const [isRecording, setIsRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const audio2Ref = useRef(new Audio());

    const [currentResponse, setCurrentResponse] = useState('');

    const handleNext = () => {
        if (current < data.length - 1) {
            setListenSpeak(prevResponses => [...prevResponses, currentResponse]);
            setCurrent(current + 1);
            setCurrentResponse('');
        } else {
            setListenSpeak(prevResponses => [...prevResponses, currentResponse]);
            handleNextSection();
        }
    };

    const startRecording = () => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                const recorder = new MediaRecorder(stream);
                setMediaRecorder(recorder);
                recorder.start();
                setIsRecording(true);

                recorder.ondataavailable = (event) => {
                    const blob = event.data;
                    sendAudio(blob);
                    audio2Ref.current.src = URL.createObjectURL(blob);
                };
            })
            .catch(err => console.error("Error accessing audio:", err));
    };

    const stopRecording = () => {
        if (mediaRecorder) {
            mediaRecorder.stop();
            setIsRecording(false);
        }
    };

    const sendAudio = (blob) => {
        const formData = new FormData();
        formData.append('file', blob, 'recording.wav');

        const fetchOptions = {
            method: 'POST',
            headers: {
                'api-key': 'dcb35510a0124e9ca4043d77550bb369',
            },
            body: formData
        };

        fetch('https://hermes.openai.azure.com/openai/deployments/apolo/audio/transcriptions?api-version=2023-09-01-preview&language=en-US', fetchOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data.text);
                setCurrentResponse(data.text)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };


    return (
        <div className="p-4">
            <div className="flex pb-6 justify-center">
                <p className="text-blue-950 capitalize text-xl pb-12 font-semibold">Speak about the topic for 90 seconds</p>
            </div>
            <div className="sm:flex">
            <div className="w-full sm:w-1/3">
                    <div className="flex justify-center">
                        <div onClick={playAudio} className="p-12 bg-blue-700 cursor-pointer w-12 h-12 flex justify-center items-center hover:bg-blue-600 flex rounded-full">
                            <audio src={data[current].audioPrompt} ref={audioRef} className="hidden" />
                            <button className="">
                                <RiSpeakLine className="text-yellow-300 w-8 h-8" />
                            </button>
                        </div>
            </div>
        <div className="flex justify-center">
<p className="text-gray-600 font-medium pt-2">Click to listen</p>
        </div>
            </div>
                <div className="flex w-full items-center sm:w-2/3 justify-center">
                    <button
                        className="bg-blue-500 hover:bg-blue-700 rounded p-5 rounded-full font-semibold"
                        onClick={isRecording ? stopRecording : startRecording}
                    >
                        {isRecording ? <>
                            <IoStop className=" text-red-500 hover:text-red-600 w-8 h-auto" />
                        </> : <> <FaMicrophone className=" w-8 text-white hover:text-gray-100 h-auto" /> </>}
                    </button>
                </div>
            </div>
            <div className="flex mt-4 justify-end">
                <button
                disabled={isRecording}
                    onClick={handleNext}
                    className={`bg-blue-800 hover:bg-blue-700 mr-12 rounded text-yellow-300 px-5 py-2 font-semibold mt-4 ${isRecording ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default ListenThenSpeak;