import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import Login from "./login";
import { getAuth, signOut } from 'firebase/auth';
import { auth, googleProvider } from "../firebase";
import { signInWithPopup } from 'firebase/auth';

const Header = ({ signInWithGoogle, user, isAuthenticated, setIsAuthenticated }) => {
    const [show, setShow] = useState(false)
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const closeHeader = () => {
        setIsMenuOpen(false);
    };


    const handleLogout = () => {
        signOut(auth)
            .then(() => {
                setIsAuthenticated(false)
                console.log('User logged out');
            })
            .catch((error) => {
                console.error('Logout error:', error);
            });
    };

    return (
<>
            <Login setIsAuthenticated={setIsAuthenticated} show={show} setShow={setShow} />
            <div className="flex bg-gray-100 border-b border-gray-300">
                <Link to="/" className={`w-2/5 sm:w-1/5 ${isMenuOpen ? "hidden" : "flex"} justify-center items-center`}>
                    <img className="w-2/5 sm:w-1/4 h-auto" src="https://luya.blob.core.windows.net/edux/edux-high-resolution-logo-transparent.png" alt="Logo" />
                </Link>
                <div className="w-2/5 py-8 hidden sm:flex justify-evenly">
                    <Link to="/practice"><p className="font-semibold cursor-pointer hover:text-blue-700 text-blue-600">Practice</p></Link>
                    <Link to="/pricing" className="font-semibold cursor-pointer hover:text-blue-700 text-blue-600">Pricing</Link>
                    <Link to="/contact" className="font-semibold cursor-pointer hover:text-blue-700 text-blue-600">Contact</Link>
                    <Link to="/duolingo.test"><p className="font-semibold cursor-pointer hover:text-blue-700 text-blue-600">Full DET test</p></Link>
                    {isAuthenticated &&
                        <Link to="/profile"><p className="font-semibold cursor-pointer hover:text-blue-700 text-blue-600">Profile</p></Link>
                    }
                </div>
                <div className="w-2/5 sm:flex items-center hidden justify-center">
                    <button className={`px-5 py-2 rounded border-2 font-semibold ${isAuthenticated ? "border-red-500 text-red-500" : "border-blue-500 text-blue-500"}`} onClick={isAuthenticated ? handleLogout : signInWithGoogle}> {user ? "Log out" : "Access"} </button>
                </div>
                <div className={`sm:hidden ${isMenuOpen ? "" : "w-3/5"} flex justify-end py-5 mr-4 items-center px-5`}>
                    <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="text-gray-600">
                        {isMenuOpen ? <></> : <MenuIcon className="h-8 w-8" />}
                    </button>
                </div>
                {isMenuOpen && (
                    <div className="sm:hidden w-full flex justify-center py-4 px-2">
                        <div className="w-5/6">
                            <div className="flex justify-end">
                                <Link to="/practice"><p onClick={closeHeader} className="font-semibold my-4 cursor-pointer text-gray-600">Practice</p></Link>
                            </div>
                            <div className="flex justify-end">
                                <Link to="/pricing"><p onClick={closeHeader} className="font-semibold cursor-pointer text-gray-600">Pricing</p></Link>
                            </div>
                            <div className="flex justify-end">
                                <Link to="/contact" className="font-semibold my-4 cursor-pointer hover:text-blue-700 text-blue-600">Contact</Link>
                            </div>
                            <div className="flex justify-end">
                                <Link to="/duolingo.test"><p onClick={closeHeader} className="font-semibold my-4 cursor-pointer text-gray-600">Full DET test</p></Link>
                            </div>
                            {user &&
                                <div className="flex justify-end">
                                    <Link to="/profile" className="font-semibold my-4 cursor-pointer text-gray-600">Profile</Link>
                                </div>
                            }
                            <div className="flex justify-end">
                                <button onClick={user ? handleLogout : signInWithGoogle} className="font-semibold cursor-pointer text-gray-600">
                                    {isAuthenticated ? "Log out" : "Access"}
                                </button>
                            </div>
                            <div className="flex justify-end">
                                <p onClick={() => setIsMenuOpen(!isMenuOpen)} className="font-semibold underline cursor-pointer text-red-500">Close</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
</>
    );
};

export default Header;