import React, { useState, useEffect, useRef } from "react"
import { FaPlay, FaPause } from 'react-icons/fa';
import {HiOutlineSpeakerWave} from "react-icons/hi2"

const ListenAndType = ({ data, handleNextSection, setUserInput  }) => {
    const audioRef = useRef(null);

    const playAudio = () => {
        if (audioRef.current) {
            audioRef.current.play();
        }
    };

    const [current, setCurrent] = useState(0);
    const [currentResponse, setCurrentResponse] = useState('');

    const handleNext = () => {
        if (current < data.length - 1) {
            setUserInput(prevResponses => [...prevResponses, currentResponse]);
            setCurrent(current + 1);
            setCurrentResponse('');
        } else {
            setUserInput(prevResponses => [...prevResponses, currentResponse]);
            handleNextSection();
        }
    };

    const [height, setHeight] = useState(100);

    useEffect(() => {
        const interval = setInterval(() => {
            setHeight(prevHeight => Math.max(prevHeight - (100 / 240), 0));
        }, 1000);
        return () => clearInterval(interval);
    }, []);



    return (
        <div className="p-4">
            <div className="w-full h-full flex justify-center items-center bg-gray-100">
                <div style={{ height: `${height}%` }} className="w-16 bg-blue-500"></div>
            </div>
            <div className="flex pb-6 justify-center">
                <p className="text-gray-800 text-lg font-semibold">Type the statement that you hear</p>
            </div>
                <div className="block sm:flex">
                <div className="w-full sm:w-2/5">
                    <div className="flex mt-6 justify-center">
                        <div className="flex items-center">
                            <div onClick={playAudio} className="p-8 cursor-pointer hover:bg-blue-600 bg-blue-800 flex rounded-full">
                                <audio src={data[current].audioPrompt} ref={audioRef} className="hidden" />
                                <button className="">
                                    <HiOutlineSpeakerWave className="text-yellow-300 w-6 h-6" />
                                </button>
                            </div>
                        </div>
                    </div>
                <div className="flex justify-center">
                    <p className="text-gray-700 mt-2 font-semibold">Click to listen</p>
                </div>
                </div>
                <div className="flex w-full sm:w-3/5 justify-center sm:justify-start">
                    <textarea
                        type="text"
                        value={currentResponse}
                        onChange={(e) => setCurrentResponse(e.target.value)}
                        className="border-2 font-semibold border-blue-800 text-gray-700 rounded outline-none p-2 w-full sm:w-3/4"
                        rows={6}
                        placeholder="Your response"
                    />
                </div>
                </div>
            <div className="flex mt-4 justify-end">
                <button
                    onClick={handleNext}
                    className="bg-blue-800 hover:bg-blue-700 rounded text-yellow-300 px-5 py-2 font-semibold mt-4"
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default ListenAndType;