import React, {useState, useEffect} from "react";
import { getFirestore, collection, getDocs} from 'firebase/firestore';
import {db} from "../firebase"
import {Link} from "react-router-dom"

const Blog = () => {

    const [publications, setPublications] = useState([]);
    const publicationsRef = collection(db, "posts");
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const getPublications = async () => {
            setLoading(true)
            const data = await getDocs(publicationsRef);
            setPublications(data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id })));
            console.log(publications);
            console.log("Listo")
            setLoading(false)
        }

        getPublications();
        console.log(publications);
    }, [])

    return (
<div className="border-t-2 border-gray-300">
<div className="flex justify-center">
                <p className="text-3xl text-gray-800 pt-20 font-semibold">Breaking Into AI</p>
</div>
<div className="flex justify-center">
<div className="w-5/6 sm:w-1/2">
<p className="text-xl text-gray-600 text-center pt-6 font-semibold"> 
                        Stories about starting a career in AI and deep learning from members of the global AI community | Inspiration and ideas for how you can break into AI
</p>
</div>
</div>
    {loading? <div className="flex items-center h-screen justify-center">
            <p className="text-gray-600 font-semibold">Loading posts</p>
    </div> 
    :
                <div className="flex my-20 justify-center">
                    <div className="w-5/6 sm:w-3/4 grid grid-cols-1 sm:grid-cols-3">
                        {(publications ?? []).map((publication, index) => {
                            return (
                                <Link to={`/posts/${publication.id}`} key={index} className="m-4 cursor-pointer border border-gray-200 shadow-lg rounded-t-lg pb-6">
                                    <img className="w-full rounded-t-lg h-auto " src={publication.image} />
                                    <div className="px-5 pt-4">
                                        <p className="text-gray-800 line-clamp-2 font-semibold text-xl mb-3">{publication.title}</p>
                                        <p className="text-green-500 line-clamp-2 hover:underline text-lg mb-3">{publication.Description}</p>
                                        <p className="text-gray-600 line-clamp-3 ">{publication.maintext}</p>
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                </div>
    }
</div>
        )
    }

export default Blog;