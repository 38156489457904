import React, { useState } from 'react';
import { Send } from 'lucide-react';
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore, collection, addDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyC-JWI8bIM7XZmvQkQsOGZ8N29kWiEmnKw",
    authDomain: "pp-edux.firebaseapp.com",
    databaseURL: "https://pp-edux-default-rtdb.firebaseio.com",
    projectId: "pp-edux",
    storageBucket: "pp-edux.appspot.com",
    messagingSenderId: "802243360037",
    appId: "1:802243360037:web:3a61f1a7f57999ec3a16cc",
    measurementId: "G-ZJZTM7TZPL"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const googleProvider = new GoogleAuthProvider();

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const docRef = await addDoc(collection(db, "contacts"), formData);
            console.log("Document written with ID: ", docRef.id);
            setIsSubmitted(true);
            setFormData({ name: '', email: '', message: '' });
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    };

    return (
        <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">Contact Us</h2>
            <p className="mb-4 text-gray-600">
                Have questions or need support? We're here to help! Fill out the form below or reach out to us directly.
            </p>
            <div className="mb-4">
                <strong className="block text-gray-700">Email:</strong>
                <a href="mailto:support@edux.com" className="text-blue-600 hover:underline">hector.19.ares@gmail.com</a>
            </div>
            <div className="mb-6">
                <strong className="block text-gray-700">Phone:</strong>
                <a href="tel:+11234567890" className="text-blue-600 hover:underline">+51 913454892</a>
            </div>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                </div>
                <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                </div>
                <div>
                    <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        rows="4"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    ></textarea>
                </div>
                <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Send Message
                    <Send className="ml-2 h-5 w-5" />
                </button>
            </form>
        </div>
    );
};

export default Contact;
