import React, { useState, useEffect, useRef } from "react";
import data from "../data/data_practice/speakphoto.json"
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { Configuration, OpenAIApi } from "azure-openai";

const openai = new OpenAIApi(
    new Configuration({
        azure: {
            apiKey: "c0086bc08dc64d3b8d8c89a710000e6e",
            endpoint: "https://ysis.openai.azure.com/",
            deploymentName: "luia",
        }
    }),
);


const LoadingIcon = () => {
    return (
        <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
    );
};

const Speakphoto = ({ advancedWordsList, paid, setPaid, user, score, feedback, setApiResponse } ) => {
    const [current, setCurrent] = useState(0);
    const [aiAnalysisCount, setAiAnalysisCount] = useState(0);
    const [userResponse, setUserResponse] = useState("");
    const [analysis, setAnalysis] = useState(false)
    const [loadingr, setLoadingr] = useState(false)
    const [loadingf, setLoadingf] = useState(false)
    const [isRecording, setIsRecording] = useState(false);
    const [showAnswer, setShowAnswer] = useState(false);
    const [timer, setTimer] = useState(60);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const audioRef = useRef(new Audio());

    const Submit = () => {
        setShowAnswer(true)
    }

    useEffect(() => {
        const fetchUserData = async () => {
            if (user) {
                const db = getFirestore();
                const userRef = doc(db, 'paying_users', user.email);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setPaid(userDoc.data().paid || false);
                    setAiAnalysisCount(userDoc.data().aiAnalysisCount || 0);
                }
            }
        };

        fetchUserData();
    }, [user]);

    const handleFunctionality = () => {
        if (user && paid) {
            return true;
        } else if (!paid && aiAnalysisCount < 3 && current < 3) {
            return true;
        } else {
            alert("Subscribe to get unlimited access");
            return false;
        }
    };

    useEffect(() => {
        setTimer(90);

        const interval = setInterval(() => {
            setTimer(prevTimer => prevTimer > 0 ? prevTimer - 1 : 0);
        }, 1000);

        return () => clearInterval(interval);
    }, [current]);

    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;


    const startRecording = () => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                const recorder = new MediaRecorder(stream);
                setMediaRecorder(recorder);
                recorder.start();
                setIsRecording(true);

                recorder.ondataavailable = (event) => {
                    const blob = event.data;
                    sendAudio(blob);
                    audioRef.current.src = URL.createObjectURL(blob);
                };
            })
            .catch(err => console.error("Error accessing audio:", err));
    };

    const [audio, setAudio] = useState(false)

    const sendAudio = (blob) => {
        setAudio(true)
        const formData = new FormData();
        formData.append('file', blob, 'recording.wav');

        const fetchOptions = {
            method: 'POST',
            headers: {
                'api-key': 'dcb35510a0124e9ca4043d77550bb369',
            },
            body: formData
        };

        fetch('https://hermes.openai.azure.com/openai/deployments/apolo/audio/transcriptions?api-version=2023-09-01-preview&language=en-US', fetchOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data.text);
                setUserResponse(data.text)
                setAudio(false)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const stopRecording = () => {
        if (mediaRecorder) {
            mediaRecorder.stop();
            setIsRecording(false);
        }
        Submit()
        sendAudio()
    };

    const instrucciones = `This is a sample full Duolingo English Test "Write about the photo" section. Generate the following for the user response: List of advanced words (if any), A score bewteen 10 and 160 for the user response. Finally, generate detailed feedback. This is an approximate description of the image: ${data.data[current].sample_answer} and this is the user answer: ${userResponse}. Use this format {data=["advanced_words_list": "", "score":"", "feedback": ""], The user should consider:  What is happening in this photo?  Who is doing what? Where are they doing it?  When are they doing it? Why are they doing it? Do not be strict because the user has only 90 seconds to answer.}`

    const improvedversion = `This is a sample full Duolingo English Test writing section. This is the prompt: ${data.data[current].prompt} and this is the user answer: ${userResponse}. Write an improved version of the user answer}`

    const vocabularyfeedback = `This is a sample full Duolingo English Test writing section. This is the prompt: ${data.data[current].prompt} and this is the user answer: ${userResponse}. Write feedback on the user response´s vocabulary}`

    const [loading, setLoading] = useState(false)

    const sendData = async () => {
        setLoading(true)
        const response = await openai.createChatCompletion({
            "messages": [
                { "role": "system", "content": "You assess the writing skills of a candidate" },
                { "role": "user", "content": `${instrucciones}` }
            ],
            "temperature": 0
        });
        console.log(response.data.choices[0].message.content);
        setApiResponse(response.data.choices[0].message.content)
        setShowAnswer(true)
        setLoading(false)
    }

    const [voc, setVoc] = useState("")

    const [second, setSecond] = useState(false)
    const [third, setThird] = useState(false)

    const vocabulary = async () => {
        setLoadingf(true)
        const response = await openai.createChatCompletion({
            "messages": [
                { "role": "system", "content": "You assess the vocabulary of a text" },
                { "role": "user", "content": `${vocabularyfeedback}` }
            ],
            "temperature": 0
        });
        setVoc(response.data.choices[0].message.content)
        setLoadingf(false)
        setThird(true)
    }


    const [im, setIm] = useState("")

    const improve = async () => {
        setLoadingr(true)
        const response = await openai.createChatCompletion({
            "messages": [
                { "role": "system", "content": "You write improved texts" },
                { "role": "user", "content": `${improvedversion}` }
            ],
            "temperature": 0
        });
        setIm(response.data.choices[0].message.content)
        setLoadingr(false)
        setSecond(true)
    }

    const ver = async () => {
        if (handleFunctionality()) {
            setAnalysis(true);
            sendData();
            const db = getFirestore();
            const userRef = doc(db, 'paying_users', user.email);
            await setDoc(userRef, { aiAnalysisCount_speak_about_the_photo: aiAnalysisCount + 1 }, { merge: true });
            setAiAnalysisCount(prevCount => prevCount + 1);
        }
    }



    const handleNext = async () => {
        if (current === data.data.length - 1) {
            const db = getFirestore();
            const userRef = doc(db, 'paying_users', user.email);
            await setDoc(userRef, { aiAnalysisCount_writing_sample: 0 }, { merge: true });
            setAiAnalysisCount(0);
        }
        setLoadingf(false)
        setLoading(false)
        setLoadingr(false)
        setCurrent(current + 1);
        setUserResponse('');
        setShowAnswer(false)
        setTimer(60);
        setAnalysis(false)
        setSecond(false)
        setThird(false)
        setIm("")
        setVoc("")
        setAudio(false)
    };

    return (
                <div className="flex justify-center py-20">
                <div className="w-5/6 sm:w-3/5 rounded border shadow-2xl border-gray-200 p-4">
                <div className="px-4">
                    <div className="py-6">
                        <button className="px-5 text-lg py-2 rounded border-2 border-blue-800 text-blue-800 font-semibold">
                            {timer > 0 ? `${minutes}:${seconds.toString().padStart(2, '0')}` : "Time's up!"}
                        </button>
                    </div>
                    <div className="flex pb-6 justify-center">
                        <p className="text-blue-900 text-xl py-8 uppercase font-semibold">Speak about the image below for 90 seconds.</p>
                    </div>
                    <div className="sm:flex mt-4">
                        <div className="flex w-full sm:w-2/5 justify-center">
                            <img src={data.data[current].photoURL} alt="Duolingo Test" className="mb-4 rounded w-7/12 h-auto" />
                        </div>
                        <div className="flex justify-center w-full py-8 sm:w-3/5 pr-5">
                            <div className="w-1/5 flex w-full justify-center mt-0">
                                <button
                                    onClick={isRecording ? stopRecording : startRecording}
                                    className="bg-blue-800 hover:bg-blue-700 flex items-center justify-center rounded text-yellow-300 p-16 h-12 w-12 rounded-full font-semibold">
                                    {isRecording ? 'Stop Recording' : 'Record Now'}
                                </button>
</div>

                        </div>
                    </div>
                    {audio?<div className="flex justify-center pt-20">
                    <LoadingIcon/>
                    </div>:<>
                        {showAnswer &&
<>
                                <div className="flex justify-center">
                                    <div className="w-full rounded border border-gray-200 shadow-lg p-4 my-10">
                                        <p className="text-xl font-semibold text-gray-800 mt-4 mb-2">Your response</p>
                                        <p className="text-lg text-gray-600 mb-4">{userResponse}</p>
                                    </div>
                                </div>
                                <div className="flex justify-center mt-6 mb-16">
                                    <button
                                        onClick={ver}
                                        disabled={isRecording}
                                        className={`bg-blue-800 hover:bg-blue-700 mr-12 rounded text-yellow-300 px-5 py-2 font-semibold mt-4 ${isRecording ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    >
                                        Submit
                                    </button>
                                </div>
</>
                        }
                    </>}
                    {loading?<div className="flex justify-center pt-20">
                        <LoadingIcon/>
                    </div>:<>
                        {analysis &&
                            <>
                                <div className="flex justify-center">
                                    <div className="w-full rounded border shadow-lg border-gray-200 p-4 my-10">
                                        <p className="flex text-gray-500 mt-4 font-medium">Estimated score:
                                        </p>
                                        <p className="text-gray-800 font-medium mt-3 mb-7 text-lg">
                                            {score}
                                        </p>
                                        <div className="flex justify-center">
                                            <div className="mt-12 w-11/12 sm:w-5/6 mb-7 rounded border border-gray-600 p-2 2xl:p-4">
                                                <p className="flex text-gray-500 mb-3 font-medium">Advanced Words List:
                                                </p>
                                                <p className="text-gray-800 font-medium">{advancedWordsList}</p>
                                            </div>
                                        </div>
                                        <p className="text-gray-500 mb-3 font-medium">Feedback:
                                        </p>
                                        <p className="text-gray-600">
                                            {feedback}
                                        </p>
                                        <div className="flex pb-12 justify-evenly pt-12">
                                            <button onClick={improve} className="bg-blue-800 hover:bg-blue-700 rounded text-yellow-300 px-5 py-2 font-semibold">See improved version</button>
                                            <button onClick={vocabulary} className="bg-blue-800 hover:bg-blue-700 rounded text-yellow-300 px-5 py-2 font-semibold">Vocabulary feedback</button>
                                        </div>
                                    </div>
                                </div>
                            </>}
                    </>}
                    {loadingr ?
                        <div className="flex justify-center pt-12">
                            <LoadingIcon />
                        </div> :
                        <div>
                            {second && <p className="text-lg font-semibold text-gray-800 mb-3 mt-6">Improved version</p>}
                            <p className="text-gray-600">
                                {im}
                            </p>
                        </div>
                    }
                    {loadingf ?
                        <div className="flex justify-center pt-12">
                            <LoadingIcon />
                        </div> :
                        <div>
                            {third && <p className="text-lg font-semibold text-gray-800 mb-3 mt-6">Vocabulary feedback</p>}
                            <p className="text-gray-600">
                                {voc}
                            </p>
                        </div>

                    }
                    <div className="flex mt-4 justify-evenly sm:justify-end">
                        <button
                            onClick={handleNext}
                            className="bg-blue-800 hover:bg-blue-700 rounded text-yellow-300 px-5 py-2 font-semibold mt-4"
                        >
                            Next
                        </button>
                    </div>
                </div>
</div>
</div>
    )
}

export default Speakphoto;
