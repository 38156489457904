import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from "../firebase";

const Signup = ({ isAuthenticated, toggleModal, isOpen,  setIsAuthenticated }) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');


    useEffect(() => { 
        const user = localStorage.getItem('user'); 
        if (user) { 
            setIsAuthenticated(true);
            navigate("/"); } 
        }, [navigate, setIsAuthenticated]);

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            console.log(user);
            localStorage.setItem('user', JSON.stringify(user));
            setIsAuthenticated(true); // Update the authentication state  
            toggleModal(); // Close the modal  
            navigate("/"); // Navigate to the home page  
        } catch (error) {
            // Handle errors here  
        }  
    };

    return (
        <>
            {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="modal-overlay absolute w-full h-full bg-gray-900 bg-opacity-50"></div>

                    <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-lg shadow-xl z-50 overflow-y-auto">
                        <div className="modal-content py-4 text-left px-6">
                            <div className="pb-3">
                                <div className="flex justify-center">
                                    <p className="text-2xl pt-5 font-bold">Sign up</p>
                                </div>
                                <button
                                    onClick={toggleModal}
                                    className="modal-close p-2 rounded-md hover:bg-gray-300"
                                >
                                    {/* SVG for close icon */}
                                </button>
                            </div>

                            <div className="my-5">
                                <main>
                                    <section>
                                        <form onSubmit={onSubmit} className="space-y-6">
                                            <div>
                                                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">Email address</label>
                                                <input
                                                    type="email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    required
                                                    placeholder="Email address"
                                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                />
                                            </div>

                                            <div>
                                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                                                <input
                                                    type="password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    required
                                                    placeholder="Password"
                                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                />
                                            </div>

                                            <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Sign up</button>
                                        </form>

                                        <p className="mt-2 text-center text-sm text-gray-600">
                                            Already have an account?{' '}
                                            <NavLink to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                                                Sign in
                                            </NavLink>  
                                        </p>
                                    </section>
                                </main>
                            </div>
                            <div className="flex justify-center">
                                <div className="w-11/12 flex justify-end my-3">
                                    <button onClick={toggleModal} className="px-5 py-2 rounded bg-white font-semibold border-2 border-red-500 text-red-500">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Signup;
