import React, { useState } from 'react';

const ReadThenWrite = ({ data, handleNextSection, setRead }) => {
    const [current, setCurrent] = useState(0);

    const [currentResponse, setCurrentResponse] = useState('');

    const handleNext = () => {
        if (current < data.length - 1) {
            setRead(prevResponses => [...prevResponses, currentResponse]);
            setCurrent(current + 1);
            setCurrentResponse('');
        } else {
            setRead(prevResponses => [...prevResponses, currentResponse]);
            handleNextSection();
        }
    };

    return (
        <div className="p-4">
        <div className='flex py-8'>
                <p className='font-semibold text-blue-800'>You have 4 minutes for this task</p>
        </div>
            <div className="mb-4 text-gray-700 text-justify font-semibold">{data[current].readingText}</div>
            <textarea
                value={currentResponse}
                onChange={(e) => setCurrentResponse(e.target.value)}
                className="border outline-none rounded border-blue-500 p-2 w-full h-40"
                placeholder="Your response"
            />
            <div className="flex mt-4 justify-end">
                <button
                    onClick={handleNext}
                    className="bg-blue-800 hover:bg-blue-700 rounded text-yellow-300 px-5 py-2 font-semibold mt-4"
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default ReadThenWrite;