import { PencilIcon, PlayIcon, SparklesIcon } from "@heroicons/react/outline";
import { useState, useEffect } from "react";
import { FaPaperPlane, FaStar } from "react-icons/fa";
import axios from "axios";

const Athena = () => {
    const [question, setQuestion] = useState("");
    const [chatHistory, setChatHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [context, setContext] = useState();
    const [triggerSubmit, setTriggerSubmit] = useState(false);
    const [predefinedPrompts, setPredefinedPrompts] = useState([
        {
            icon: <SparklesIcon className="text-purple-500 w-5 h-auto" />,
            text: "Give me sample phrasal verbs I could use <br/> in the topic of travel",
        },
        {
            icon: <PlayIcon className="text-yellow-500 w-5 h-auto" />,
            text: "Give me sample fake words <br/> and ask me which ones real English words",
        },
        {
            icon: <PencilIcon className="text-blue-500 w-5 h-auto" />,
            text: "Give me a sample, hard <br/> level, writing section prompt",
        },
    ]);

    useEffect(() => {
        if (triggerSubmit) {
            handleSubmit();
        }
    }, [question]);

    const handleSubmit = async (e) => {
        if (e) e.preventDefault();
        setIsLoading(true);
        setTriggerSubmit(false);

        const headers = {
            "Content-Type": "application/json",
            "api-key": "c0086bc08dc64d3b8d8c89a710000e6e",
        };

        const params = {
            "api-version": "2023-12-01-preview",
        };

        const searchPayload = {
            messages: [{ role: "user", content: question }],
            temperature: 0.8,
            top_p: 1.0,
            max_tokens: 1000,
            dataSources: [
                {
                    type: "azure_search",
                    parameters: {
                        endpoint: "https://vortex.search.windows.net",
                        key: "n4tx5IRwVgbX4kFClh70YKp43Ip1gsZOBf4PLboavmAzSeAeUIHT",
                        indexName: "azureblob-index",
                        in_scope: false,
                        role_information: "You are an expert in the Duolingo English Test. Always provide answers, and with samples if possible",
                    },
                },
                
            ],
        };

        try {
            const response = await axios.post(
                "https://ysis.openai.azure.com/openai/deployments/luia/extensions/chat/completions",
                searchPayload,
                { headers, params }
            );
            const newMessage = {
                user: question,
                athena: response.data.choices[0].message.content,
                context: response.data.choices[0].message.context.messages[0].content
            };
            setContext(response.data.choices[0].message.context.messages[0].content)
            setChatHistory([...chatHistory, newMessage]);
            setQuestion("");
            console.log(response);
        } catch (error) {
            console.error("Error:", error);
            const newMessage = {
                user: question,
                athena: "An error occurred",
            };
            setChatHistory([...chatHistory, newMessage]);
            setQuestion("");
        }

        setIsLoading(false);
    };

    const handleButtonClick = async (predefinedQuestion) => {
        setQuestion(predefinedQuestion);
        setTriggerSubmit(true);
        setPredefinedPrompts([]);
    };

    return (
        <>

        </>
    );
};

export default Athena;
