import React from "react";

const Test = ({ currentSection, sections }) => {
    return (
        <div>
        <div className="">
            <hr className="bg-gray-200 h-1"></hr>
        </div>
            <div className="flex py-32 justify-center">
                <div className='border border-blue-700 p-2 rounded-lg w-3/4 sm:w-1/2'>
                    {sections[currentSection]}
                </div>
            </div>
        </div>
        );
    };

export default Test;