import React, { useState } from 'react';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
import {FaCheckCircle, FaMicrophone} from "react-icons/fa"

const SpeakingLab = () => {
    const [topic, setTopic] = useState('Politics');
    const [liveRecording, setLiveRecording] = useState(false);
    const [recognizedText, setRecognizedText] = useState('');
    const [reco, setReco] = useState(null);
    const [test, setTest] = useState([]);
    const [api, setApi] = useState([])
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleButtonClick = () => {
        if (!liveRecording) {
            startRecognition();
        } else {
            stopRecognition();
        }
    };

    const startRecognition = () => {
        setApi([])
        setTest([])
        const speechConfig = sdk.SpeechConfig.fromSubscription("4e3c3d23d7cd43a4b7414e6d3f4da512", "eastus");
        speechConfig.speechRecognitionLanguage = "en-US";
        setLiveRecording(true);

        const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
        const newReco = new sdk.SpeechRecognizer(speechConfig, audioConfig);
        setReco(newReco);

        const pronunciationAssessmentConfig = new sdk.PronunciationAssessmentConfig(
            "",
            sdk.PronunciationAssessmentGradingSystem.HundredMark,
            sdk.PronunciationAssessmentGranularity.Phoneme,
            false
        );
        pronunciationAssessmentConfig.enableProsodyAssessment = true;
        pronunciationAssessmentConfig.enableContentAssessmentWithTopic(topic);
        pronunciationAssessmentConfig.applyTo(newReco);

        newReco.recognizing = (s, e) => {
            var jo = JSON.parse(e.result.properties.getProperty(sdk.PropertyId.SpeechServiceResponse_JsonResult));
            if (jo.DisplayText !== ".") {
                console.log(`Recognizing: ${jo.DisplayText}`);
                setRecognizedText(prevText => prevText + jo.DisplayText + " ");
            }
        };

        newReco.recognized = (s, e) => {
            var jo = JSON.parse(e.result.properties.getProperty(sdk.PropertyId.SpeechServiceResponse_JsonResult));
            setTest(prevTest => [...prevTest, jo.DisplayText]);
            console.log(jo)
            setApi(prevApi => [...prevApi, jo]);
        };

        newReco.canceled = (s, e) => {
            if (e.reason === sdk.CancellationReason.Error) {
                var str = `(cancel) Reason: ${sdk.CancellationReason[e.reason]}: ${e.errorDetails}`;
                console.log(str);
            }
        };

        newReco.sessionStopped = (s, e) => {
            console.log('Session stopped');
            console.log("Final, ", test)
            setLiveRecording(false);
        };

        newReco.startContinuousRecognitionAsync();
    };

    const stopRecognition = () => {
        if (reco) {
            reco.stopContinuousRecognitionAsync(() => {
                console.log('Recognition stopped');
                console.log("End: ", test)
                reco.close();
                setReco(null);
                setLiveRecording(false);
            }, (error) => {
                console.error(error);
            });
        }
    };

    return (
        <div className=''>
        
<div className='flex justify-center'>
<div className='w-4/5 grid pt-20 grid-cols-1 sm:grid-cols-2'>
                    <div className='my-8 flex justify-center'>
                        <div className='w-full sm:w-3/4 my-6 rounded border-2 shadow-xl bg-white hover:bg-gray-100 cursor-pointer border-blue-500 p-4'>
                        <div className='flex justify-center pb-6'>
                                <p className='text-gray-500 text-center text-sm'>Get ready and start recording your discussion on this topic when you're ready.</p>
                        </div>
                                <div className='flex justify-center'>
                                <button onClick={handleButtonClick} className="bg-white hover:bg-blue-100 rounded-full border border-blue-500 bg-white mt-6">
                                    <FaMicrophone className={`${liveRecording ? "text-red-500" : "text-blue-500" } w-6 h-6 m-4`} />
                                </button>
                                </div>
                                <div className='flex pb-6 justify-center'>
                                <p className='text-gray-500 text-sm'>{liveRecording ? 'Press the microphone to stop recording' : 'Press the microphone to start speaking'}</p>
                                </div>
                        <div className='flex space-x-4 justify-evenly pb-6'>
                                <a className='flex items-center text-xs text-gray-500'> <FaCheckCircle className='w-4 mx-1 h-auto text-green-500' /> +15 sec </a>
                                <a className='flex items-center text-xs text-gray-500'> <FaCheckCircle className='w-4 mx-1 h-auto text-green-500' /> +50 words </a>
                                <a className='flex items-center text-xs text-gray-500'> <FaCheckCircle className='w-4 mx-1 h-auto text-green-500' /> +3 sentences </a>                                
                        </div>
                        </div>
                    </div>
                    <div className='flex items-center justify-center'>
                    <div>
                            <p className='mr-2 font-medium text-lg pb-2 text-gray-600'>Select a topic to talk about: </p>
                            <select value={topic} onChange={(e) => setTopic(e.target.value)}
                                class="form-select rounded-lg block w-full px-4 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                                <option value="Politics">Politics</option>
                                <option value="Technology">Technology</option>
                                <option value="Health">Health</option>
                                <option value="Personal">Personal</option>
                                <option value="Hobbies">Hobbies</option>
                                <option value="Travel">Travel</option>
                                <option value="Books">Books</option>
                                <option value="Movies">Movies</option>
                                <option value="Music">Music</option>
                                <option value="Food">Food</option>
                                <option value="Sports">Sports</option>
                                <option value="TalkAboutYourDay">Talk about your day</option>
                                <option value="FavoritePet">Favorite pet</option>
                            </select>

                    </div>
                    </div>
</div>
</div>
            <div className='pt-12 flex justify-center'>
                <div className="w-4/5">
                    {test?.map((element, index) => (
                        <span
                            key={index}
                            className={`cursor-pointer underline-offset-4 ${selectedIndex === index
                                    ? 'underline decoration-sky-500 decoration-dashed'
                                    : 'hover:underline hover:decoration-sky-500 hover:decoration-dashed'
                                }`}
                            onClick={() => setSelectedIndex(index)}
                        >
                            {element + " "}
                        </span>
                    ))}
                </div>
            </div>

            {selectedIndex !== null && api && api[selectedIndex] && (
                <div className='px-6 '>
                    {api[selectedIndex]?.NBest?.map((element, nbestIndex)=> 
                        <div key={nbestIndex}>
                    <>
                                {element?.Confidence &&
                                <>
                                    <div className='pt-12 sm:flex justify-center'>
                                        <div className="w-full sm:w-4/5 py-12 sm:flex">
                                            <div className='w-full sm:w-1/3'>
                                                <p className='text-gray-600'>Confidence: {element?.Confidence}</p>
                                                <p className='text-gray-800 font-semibold pb-3'>Pronunciation Score: {element?.PronunciationAssessment?.PronScore}</p>
                                                <div className='flex justify-center'>
                                                    <svg width="100" height="100" viewBox="0 0 36 36" className="circular-chart">
                                                        <path className="circle-bg"
                                                            d="M18 2.0845
                                    a 15.9155 15.9155 0 0 1 0 31.831
                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                                            fill="none" stroke="#eee" strokeWidth="4" />
                                                        <path className="circle"
                                                            d="M18 2.0845
                                        a 15.9155 15.9155 0 0 1 0 31.831
                                        a 15.9155 15.9155 0 0 1 0 -31.831"
                                                            fill="none" stroke="#00cc99" strokeWidth="4" strokeDasharray={`${element?.PronunciationAssessment?.PronScore}, 100`} />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className='w-full sm:w-2/3'>
                                                <div className='grid py-6 py-12 grid-cols-1 sm:grid-cols-2'>
                                                    <div className='px-2'>
                                                        <div className='flex w-full justify-between'>
                                                            <p className='text-gray-600'>Fluency Score: </p>
                                                            <p className='text-gray-600'>{element?.PronunciationAssessment?.FluencyScore}/100</p>
                                                        </div>
                                                        <div style={{ width: '100%', backgroundColor: '#eee', height: '15px' }}>
                                                            <div className='rounded' style={{ width: `${element?.PronunciationAssessment?.FluencyScore}%`, backgroundColor: 'blue', height: '100%' }}></div>
                                                        </div>
                                                        <div className='flex w-full justify-between'>
                                                            <p className='text-gray-600 pt-4'>Prosody Score: </p>
                                                            <p className='text-gray-600 pt-4'>{element?.PronunciationAssessment?.ProsodyScore}/100</p>
                                                        </div>
                                                        <div style={{ width: '100%', backgroundColor: '#eee', height: '15px' }}>
                                                            <div className='rounded' style={{ width: `${element?.PronunciationAssessment?.ProsodyScore}%`, backgroundColor: 'blue', height: '100%' }}></div>
                                                        </div>
                                                    </div>
                                                    <div className='px-2'>
                                                        <div className='flex w-full justify-between'>
                                                            <p className='text-gray-600'>Completeness Score: </p>
                                                            <p className='text-gray-600'>{element?.PronunciationAssessment?.CompletenessScore}/100</p>
                                                        </div>
                                                        <div style={{ width: '100%', backgroundColor: '#eee', height: '15px' }}>
                                                            <div className='rounded' style={{ width: `${element?.PronunciationAssessment?.CompletenessScore}%`, backgroundColor: 'blue', height: '100%' }}></div>
                                                        </div>
                                                        <div className='flex w-full justify-between'>
                                                            <p className='text-gray-600 pt-4'>Accuracy Score: </p>
                                                            <p className='text-gray-600 pt-4'>{element?.PronunciationAssessment?.AccuracyScore}/100</p>
                                                        </div>
                                                        <div style={{ width: '100%', backgroundColor: '#eee', height: '15px' }}>
                                                            <div className='rounded' style={{ width: `${element?.PronunciationAssessment?.AccuracyScore}%`, backgroundColor: 'blue', height: '100%' }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-3 flex justify-center">
                                        <div className='w-4/5'>
                                            <p className="font-bold">Detailed analysis :</p>
                                            <div>
                                                {element?.Words?.map((word, index) => (
                                                    <a key={index} className="mb-2 inline-flex">
                                                        <p className={`${word?.PronunciationAssessment?.ErrorType === "Mispronunciation" ? "text-red-500" : word?.PronunciationAssessment?.ErrorType === "Insertion" ? "text-blue-600" : "text-gray-500"}`}>
                                                            {word.Word}<a className='text-white'>{"h"}</a>
                                                        </p>
                                                    </a>
                                                ))}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="py-3 flex justify-center">
                                        <div class='w-4/5'>
                                            <p><div class="inline-block w-4 h-4 bg-red-500 mr-2"></div>Mispronunciation</p>
                                            <p class='pt-2'><div class="inline-block w-4 h-4 bg-blue-500 mr-2"></div>Insertion</p>
                                        </div>

                                    </div>
                                </>
                                }
                    </>
                    </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SpeakingLab;
