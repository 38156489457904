import React, { useState, useEffect } from "react";
import data from "../data/data_practice/readselect.json";

const Readselect = () => {
    const [current, setCurrent] = useState(0);
    const [timer, setTimer] = useState(60);
    const [selectedWords, setSelectedWords] = useState({});
    const [submitted, setSubmitted] = useState(false);

    const handleNext = () => {
        setCurrent(current + 1);
        setTimer(60);
        setSelectedWords({});
        setSubmitted(false);
    };

    const words = Object.keys(data.data[current].words);

    const toggleWordSelection = (word) => {
        setSelectedWords(prev => ({
            ...prev,
            [word]: !prev[word]
        }));
    };

    useEffect(() => {
        setTimer(60);

        const interval = setInterval(() => {
            setTimer(prevTimer => prevTimer > 0 ? prevTimer - 1 : 0);
        }, 1000);

        return () => clearInterval(interval);
    }, [current]);

    const Submit = () => {
        setSubmitted(true);
    };

    const getButtonClassName = (word) => {
        if (submitted) {
            return `w-11/12 border-2 ${data.data[current].words[word] ? 'border-green-500' : 'border-red-500'} bg-yellow-100 py-2 rounded text-blue-800 font-semibold`;
        }
        return `w-11/12 border-2 ${selectedWords[word] ? 'bg-yellow-500' : 'bg-yellow-100'} py-2 rounded text-blue-800 font-semibold`;
    };

    return (
        <>
            <div className="flex justify-center py-20">
                <div className="w-11/12 sm:w-5/6 2xl:w-3/5 rounded border shadow-2xl border-gray-200 py-8 px-4">
                    <div id="timer">
                        <p className="text-lg pl-6 pt-6 font-semibold text-blue-800">{timer > 0 ? `${timer} seconds left` : "Time's up!"}</p>
                    </div>
                    <div className="flex pb-2 pt-4 justify-center">
                        <p className="text-gray-800 text-lg font-semibold">Select the real English words</p>
                    </div>
                    <div className='flex justify-center'>
                        <div className='w-11/12 sm:w-3/4 grid mt-8 grid-cols-2 sm:grid-cols-4 gap-4'>
                            {words.map((word, index) => (
                                <div className='flex justify-center' key={index}>
                                    <button
                                        className={getButtonClassName(word)}
                                        onClick={() => toggleWordSelection(word)}
                                    >
                                        {word}
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex mt-4 justify-end">
                        <button
                            onClick={Submit}
                            className="bg-blue-800 hover:bg-blue-700 mr-12 rounded text-yellow-300 px-5 py-2 font-semibold mt-4"
                        >
                            Submit
                        </button>
                        <button
                            onClick={handleNext}
                            className="bg-blue-800 hover:bg-blue-700 rounded text-yellow-300 px-5 py-2 font-semibold mt-4"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Readselect;
