import React, { useEffect, useState } from 'react';
import { auth, db } from './firebase';
import { doc, setDoc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';

const UserSession = () => {
    const [startTime, setStartTime] = useState(Date.now());

    useEffect(() => {
        const startSession = async () => {
            try {
                const user = auth.currentUser;
                if (user) {
                    const sessionRef = doc(db, 'sessions', user.uid);
                    const sessionSnap = await getDoc(sessionRef);

                    if (sessionSnap.exists()) {
                        // Update startTime with serverTimestamp  
                        await updateDoc(sessionRef, {
                            startTime: serverTimestamp(),
                        });
                    } else {
                        // Initialize session with startTime and totalDuration  
                        await setDoc(sessionRef, {
                            startTime: serverTimestamp(),
                            totalDuration: 0,
                        });
                    }

                    // Store the start time locally when the session starts  
                    setStartTime(Date.now());
                }
            } catch (error) {
                console.error('Error starting session:', error);
            }
        };

        startSession();

        return () => {
            const endSession = async () => {
                const user = auth.currentUser;
                if (user) {
                    const sessionRef = doc(db, 'sessions', user.uid);

                    // Calculate duration in milliseconds based on the locally stored start time  
                    const duration = Date.now() - startTime;

                    const sessionData = await getDoc(sessionRef);
                    // Update totalDuration with calculated duration  
                    await updateDoc(sessionRef, {
                        totalDuration: sessionData.data().totalDuration + duration,
                    });
                }
            };

            endSession();
        };
    }, [startTime]);

    return null;
};

export default UserSession;  
