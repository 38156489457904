import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {ArrowCircleRightIcon} from "@heroicons/react/solid"
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { NavLink, useNavigate } from 'react-router-dom'
import { signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from "../../firebase"
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Practice= ({user, paid, setPaid}) => {

    const data = {
        labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        datasets: [
            {
                label: '# hours of practice',
                data: [12, 19, 3, 5, 2, 3, 7],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const percentage = 91; 
    const signInWithGoogle = async () => {
        try {
            await signInWithPopup(auth, googleProvider);
        } catch (error) {
            console.error("Error signing in with Google:", error);
        }
    };


    const navigate = useNavigate();

    const seereport = () => {
        navigate("/profile");
    }
    const next1 = (e) => {
        e.preventDefault();
        navigate("/listentype");
    }

    const next2 = (e) => {
        e.preventDefault();
        navigate("/listenspeak");
    }

    const next3 = (e) => {
        e.preventDefault();
        navigate("/readaloud");
    }

    const next4 = (e) => {
        e.preventDefault();
        navigate("/readspeak");
    }

    const next5 = (e) => {
        e.preventDefault();
        navigate("/readselect");
    }

    const next6 = (e) => {
        e.preventDefault();
        navigate("/speakingsample");
    }

    const next7 = (e) => {
        e.preventDefault();
        navigate("/readwrite");
    }

    const next8 = (e) => {
        e.preventDefault();
        navigate("/writephoto");
    }

    const next9 = (e) => {
        e.preventDefault();
        navigate("/speakphoto");
    }

    useEffect(() => {
        const fetchPaymentStatus = async () => {
            if (user) {
                const db = getFirestore();
                const userRef = doc(db, 'paying_users', user.email);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setPaid(userDoc.data().paid || false);
                }
            }
        };

        fetchPaymentStatus();
    }, [user]);


    const handleLab = () => {
            navigate("/speakinglabnew");
    };

    return (
        <>
<div className="w-full flex justify-center">
<div className="w-4/5 pt-16 pb-10 flex justify-center">
  <p className="text-blue-800 text-2xl py-8 font-bold">Try the Speaking Lab</p>
</div>
</div>
<div className="flex justify-center">
                    <div className=" w-11/12 rounded border border-gray-300 sm:w-11/12 2xl:w-4/5 items-center justify-center">
                        <div className='pt-12 sm:flex justify-center'>
                            <div className="w-full sm:w-4/5 py-12 sm:flex">
                                <div className='w-full px-4 sm:w-1/3'>
                <div className="flex justify-center">
                <div className="w-2/3">
                                        <CircularProgressbar
                                            value={percentage}
                                            maxValue={100}
                                            text={`${percentage}%`}
                                            styles={{
                                                path: {
                                                    stroke: '#00bf99',
                                                    strokeLinecap: 'butt',
                                                    transition: 'stroke-dashoffset 0.5s ease 0s',
                                                },
                                                trail: {
                                                    stroke: '#eee',
                                                },
                                                text: {
                                                    fill: '#000',
                                                    fontSize: '13px',
                                                },
                                            }}
                                        />
</div>
</div>
                                <div class='w-full flex justify-center py-5 flex items-center space-x-3'>
                                    <p className="text-sm"><div class="inline-block inline-flex w-4 mx-1 h-4 bg-red-700"></div>0 ~ 59</p>
                                    <p className="text-sm"><div class="inline-block mx-1 w-4 h-4 bg-yellow-700"></div>60 ~ 79</p>
                                    <p className="text-sm"><div class="inline-block w-4 h-4 mx-1" style={{ backgroundColor: '#00bf99' }}></div>80 ~ 100</p>
                                </div>
                                </div>
                                <div className='w-full px-4 sm:w-2/3'>
                                    <div className='grid py-6 py-12 grid-cols-1 sm:grid-cols-2'>
                                        <div className='px-2'>
                                            <div className='flex w-full justify-between'>
                                                <p className='text-gray-600'>Fluency Score: </p>
                                                <p className='text-gray-600'>96/100</p>
                                            </div>
                                            <div style={{ width: '100%', backgroundColor: '#eee', height: '15px' }}>
                                                <div className='rounded' style={{ width: `$96%`, backgroundColor: 'blue', height: '100%' }}></div>
                                            </div>
                                            <div className='flex w-full justify-between'>
                                                <p className='text-gray-600 pt-4'>Prosody Score: </p>
                                                <p className='text-gray-600 pt-4'>90/100</p>
                                            </div>
                                            <div style={{ width: '100%', backgroundColor: '#eee', height: '15px' }}>
                                                <div className='rounded' style={{ width: `90%`, backgroundColor: 'blue', height: '100%' }}></div>
                                            </div>
                                        </div>
                                        <div className='px-2'>
                                            <div className='flex w-full justify-between'>
                                                <p className='text-gray-600'>Completeness Score: </p>
                                                <p className='text-gray-600'>94/100</p>
                                            </div>
                                            <div style={{ width: '100%', backgroundColor: '#eee', height: '15px' }}>
                                                <div className='rounded' style={{ width: `94%`, backgroundColor: 'blue', height: '100%' }}></div>
                                            </div>
                                            <div className='flex w-full justify-between'>
                                                <p className='text-gray-600 pt-4'>Accuracy Score: </p>
                                                <p className='text-gray-600 pt-4'>86/100</p>
                                            </div>
                                            <div style={{ width: '100%', backgroundColor: '#eee', height: '15px' }}>
                                                <div className='rounded' style={{ width: `$86%`, backgroundColor: 'blue', height: '100%' }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="py-3 px-4 flex justify-center">
                            <div className='w-full sm:w-4/5'>
                                <p className="font-bold">Detailed analysis :</p>
                                <div>
                                <p className="">Hello. My name is Hector and I really enjoy <a className="text-red-500 px-1">reading</a> history books and listening to classical <a className="px-1 text-blue-500 line-through"> clasical </a> music.</p>
                                </div>

                            </div>
                        </div>
                        <div className="py-3 flex justify-center">
                            <div class='w-4/5'>
                                <p><div class="inline-block w-4 h-4 bg-red-500 mr-2"></div>Mispronunciation</p>
                                <p class='pt-2'><div class="inline-block w-4 h-4 bg-blue-500 mr-2"></div>Insertion</p>
                            </div>

                        </div>
                    <div className="flex justify-center"> 
                    <button 
                    onClick={handleLab} 
                    className="px-5 py-2 rounded border-2 border-green-500 text-green-500 mt-6 mb-16 font-semibold bg-white hover:bg-green-100">
                    Go to the Lab</button>
                    </div>
                    </div>
</div>
        <div className="flex justify-center">
            <div className="w-full sm:w-4/5 sm:flex">
            <div className="w-full sm:w-1/2 pt-20 pb-12">
                        <p className="py-12 pl-10 text-gray-800 font-semibold text-lg">Practice the different sections of the Duolingo english Test</p>
            </div>
            <div className="w-full sm:w-1/2 py-12 flex justify-center">
                <div className="flex items-center">
                            <button onClick={(e) => user ? seereport(e) : signInWithGoogle(e)} className="px-5 rounded py-2 hover:bg-blue-600 bg-blue-800 text-yellow-300 font-semibold">See your profile report</button>
                </div>
            </div>
            </div>
        </div>
        <div className="flex justify-center">
<div className="w-full 2xl:w-4/5 grid grid-cols-1 sm:grid-cols-4">
                    <Link to="" className="flex justify-center py-5">
                        <a onClick={(e)=> user ? next1(e) : signInWithGoogle(e)} className=" mb-5 rounded-tr-3xl shadow hover:shadow-xl w-3/4">
                            <div className=" flex bg-cover bg-[url('https://luya.blob.core.windows.net/edux/writing.png')] rounded-tr-3xl justify-center">
                                <div className="w-4/5">
                                <p className="pt-32 text-transparent">Hola</p>
                                </div>
                            </div>
                    <div className="border-b-2 border-x-2 border-blue-500 pb-4">
                                <div className="flex justify-center">
                                    <div className="w-4/5">
                                        <p className="pb-2 mt-8 font-semibold text-purple-600">Listen and type</p>
                                        <button className="mt-3 text-sm px-5 font-semibold border-2 border-blue-600 rounded py-2 text-blue-600 roundedt">Instructions</button>
                                        <p href="#" className="text-blue-600 font-semibold text-sm mt-2 hover:text-blue-800 transition duration-300 ease-in-out">+1K sample questions</p>
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <Link className="w-4/5 pt-16 flex justify-around">
                                        <p className="text-blue-600 cursor-pointer hover:underline font-semibold">Go to course</p>
                                        <ArrowCircleRightIcon className="w-6 h-6 text-blue-600 hover:text-blue-700 cursor-pointer" />
                                    </Link>
                                </div>
                    </div>
                        </a>
                    </Link>
                    <div className="">
                        <Link to="" className="flex justify-center py-5">
                            <Link onClick={(e) => user ? next2(e) : signInWithGoogle(e)} className=" mb-5 rounded-tr-3xl shadow hover:shadow-xl  w-3/4">
                                <div className=" flex bg-cover bg-[url('https://images.sbs.com.au/drupal/news/public/malala_lead_0.jpg')] rounded-tr-3xl justify-center">
                                    <div className="w-4/5">
                                        <p className="pt-32 text-transparent">Hola</p>
                                    </div>
                                </div>
<div className="border-b-2 border-x-2 border-blue-500 pb-4">
                                    <div className="flex justify-center">
                                        <div className="w-4/5">
                                            <p className="pb-2 mt-8 font-semibold text-purple-600">Listen then speak</p>
                                            <button className="mt-3 text-sm px-5 font-semibold border-2 border-blue-600 rounded py-2 text-blue-600 roundedt">Instructions</button>
                                            <p href="#" className="text-blue-600 font-semibold text-sm mt-2 hover:text-blue-800 transition duration-300 ease-in-out">+1K sample questions</p>
                                        </div>
                                    </div>
                                    <div className="flex justify-center">
                                        <Link className="w-4/5 pt-16 flex justify-around">
                                            <p className="text-blue-600 cursor-pointer hover:underline font-semibold">Go to course</p>
                                            <ArrowCircleRightIcon className="w-6 h-6 text-blue-600 hover:text-blue-700 cursor-pointer" />
                                        </Link>
                                    </div>
</div>
                            </Link>
                        </Link>
                    </div>
                    <Link to="" className="flex justify-center py-5">
                        <Link onClick={(e) => user ? next3(e) : signInWithGoogle(e)} className=" mb-5 rounded-tr-3xl shadow hover:shadow-xl w-3/4">
                            <div className=" flex bg-cover bg-[url('https://cdn.carnegielearning.com/assets/blog-images/reading_aloud_blog_small_1.png')] rounded-tr-3xl justify-center">
                                <div className="w-4/5">
                                    <p className="pt-32 text-transparent">Hola</p>
                                </div>
                            </div>
<div className="border-b-2 border-x-2 pb-4 border-blue-500">
                                <div className="flex justify-center">
                                    <div className="w-4/5">
                                        <p className="pb-2 mt-8 font-semibold text-purple-600">Read aloud</p>
                                        <button className="mt-3 text-sm px-5 font-semibold border-2 border-blue-600 rounded py-2 text-blue-600 roundedt">Instructions</button>
                                        <p href="#" className="text-blue-600 font-semibold text-sm mt-2 hover:text-blue-800 transition duration-300 ease-in-out">+1K sample questions</p>
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <Link  className="w-4/5 pt-16 flex justify-around">
                                        <p className="text-blue-600 cursor-pointer hover:underline font-semibold">Go to course</p>
                                        <ArrowCircleRightIcon className="w-6 h-6 text-blue-600 hover:text-blue-700 cursor-pointer" />
                                    </Link>
                                </div>
</div>
                        </Link>
                    </Link>
                    <Link to="" className="flex justify-center py-5">
                        <Link onClick={(e) => user ? next4(e) : signInWithGoogle(e)} className=" mb-5 rounded-tr-3xl shadow hover:shadow-xl  w-3/4">
                            <div className=" flex bg-cover bg-[url('https://cce.grad-college.iastate.edu/sites/default/files/pictures/Stock%20Photos/ESpeaking2.jpg')] rounded-tr-3xl justify-center">
                                <div className="w-4/5">
                                    <p className="pt-32 text-transparent">Hola</p>
                                </div>
                            </div>
<div className="border-b-2 border-x-2 border-blue-500 pb-4">
                                <div className="flex justify-center">
                                    <div className="w-4/5">
                                        <p className="pb-2 mt-8 font-semibold text-purple-600">Read then speak</p>
                                        <button className="mt-3 text-sm px-5 font-semibold border-2 border-blue-600 rounded py-2 text-blue-600 roundedt">Instructions</button>
                                        <p href="#" className="text-blue-600 font-semibold text-sm mt-2 hover:text-blue-800 transition duration-300 ease-in-out">+1K sample questions</p>
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <Link  className="w-4/5 pt-16 flex justify-around">
                                        <p className="text-blue-600 cursor-pointer hover:underline font-semibold">Go to course</p>
                                        <ArrowCircleRightIcon className="w-6 h-6 text-blue-600 hover:text-blue-700 cursor-pointer" />
                                    </Link>
                                </div>
</div>
                        </Link>
                    </Link>
                </div>
                </div>
                    <div className="flex justify-center">
                    <div className="w-full 2xl:w-4/5 grid grid-cols-1 sm:grid-cols-4">
                    <Link to="" className="flex justify-center py-5">
                        <Link onClick={(e) => user ? next5(e) : signInWithGoogle(e)} className=" mb-5 rounded-tr-3xl shadow hover:shadow-xl w-3/4">
                            <div className=" flex bg-cover bg-[url('https://static.independent.co.uk/s3fs-public/thumbnails/image/2016/04/15/16/student-reading.jpg')] rounded-tr-3xl justify-center">
                                <div className="w-4/5">
                                    <p className="pt-32 text-transparent">Hola</p>
                                </div>
                            </div>
                        <div className="border-b-2 border-x-2 pb-4 border-blue-500">
                                <div className="flex justify-center">
                                    <div className="w-4/5">
                                        <p className="pb-2 mt-8 font-semibold text-purple-600">Read then select</p>
                                        <button className="mt-3 text-sm px-5 font-semibold border-2 border-blue-600 rounded py-2 text-blue-600 roundedt">Instructions</button>
                                        <p href="#" className="text-blue-600 font-semibold text-sm mt-2 hover:text-blue-800 transition duration-300 ease-in-out">+1K sample questions</p>
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <Link to="/readselect" className="w-4/5 pt-16 flex justify-around">
                                        <p className="text-blue-600 cursor-pointer hover:underline font-semibold">Go to course</p>
                                        <ArrowCircleRightIcon className="w-6 h-6 text-blue-600 hover:text-blue-700 cursor-pointer" />
                                    </Link>
                                </div>
                    </div>
                        </Link>
                    </Link>
                    <Link to="" className="flex justify-center py-5">
                        <Link onClick={(e) => user ? next6(e) : signInWithGoogle(e)} className=" mb-5 rounded-tr-3xl shadow hover:shadow-xl  w-3/4">
                            <div className=" flex bg-cover bg-[url('https://marymount.edu/wp-content/uploads/2023/01/700.jpg')] rounded-tr-3xl justify-center">
                                <div className="w-4/5">
                                    <p className="pt-32 text-transparent">Hola</p>
                                </div>
                            </div>
                <div className="border-b-2 border-x-2 border-blue-500 pb-4">
                                <div className="flex justify-center">
                                    <div className="w-4/5">
                                        <p className="pb-2 mt-8 font-semibold text-purple-600">Speaking sample</p>
                                        <button className="mt-3 text-sm px-5 font-semibold border-2 border-blue-600 rounded py-2 text-blue-600 roundedt">Instructions</button>
                                        <p href="#" className="text-blue-600 font-semibold text-sm mt-2 hover:text-blue-800 transition duration-300 ease-in-out">+1K sample questions</p>
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <Link  className="w-4/5 pt-16 flex justify-around">
                                        <p className="text-blue-600 cursor-pointer hover:underline font-semibold">Go to course</p>
                                        <ArrowCircleRightIcon className="w-6 h-6 text-blue-600 hover:text-blue-700 cursor-pointer" />
                                    </Link>
                                </div>
                        </div>
                        </Link>
                    </Link>
                    <Link to="" className="flex justify-center py-5">
                        <Link onClick={(e) => user ? next7(e) : signInWithGoogle(e)} className=" mb-5 rounded-tr-3xl shadow hover:shadow-xl  w-3/4">
                            <div className=" flex bg-cover bg-[url('https://img.freepik.com/premium-photo/portrait-smart-asian-man-university-student-reading-book-bookshelves-campus-library-with-copyspace_73503-2734.jpg')] rounded-tr-3xl justify-center">
                                <div className="w-4/5">
                                    <p className="pt-32 text-transparent">Hola</p>
                                </div>
                            </div>
                    <div className="border-b-2 border-x-2 pb-4 border-blue-500">
                                <div className="flex justify-center">
                                    <div className="w-4/5">
                                        <p className="pb-2 mt-8 font-semibold text-purple-600">Read then write</p>
                                        <button className="mt-3 text-sm px-5 font-semibold border-2 border-blue-600 rounded py-2 text-blue-600 roundedt">Instructions</button>
                                        <p href="#" className="text-blue-600 font-semibold text-sm mt-2 hover:text-blue-800 transition duration-300 ease-in-out">+1K sample questions</p>
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <Link className="w-4/5 pt-16 flex justify-around">
                                        <p className="text-blue-600 cursor-pointer hover:underline font-semibold">Go to course</p>
                                        <ArrowCircleRightIcon className="w-6 h-6 text-blue-600 hover:text-blue-700 cursor-pointer" />
                                    </Link>
                                </div>
                        </div>
                        </Link>
                    </Link>
                    <Link to="" className="flex justify-center py-5">
                        <a onClick={(e) => user ? next8(e) : signInWithGoogle(e)} className=" mb-5 rounded-tr-3xl shadow hover:shadow-xl  w-3/4">
                            <div className=" flex bg-cover bg-[url('https://ubertutors.co.uk/wp-content/uploads/2021/02/University-essay-writing-tips-How-to-get-better-at-essay-writing-scaled.jpg')] rounded-tr-3xl justify-center">
                                <div className="w-4/5">
                                    <p className="pt-32 text-transparent">Hola</p>
                                </div>
                            </div>
                    <div className="border-b-2 border-x-2 border-blue-500 pb-4">
                                <div className="flex justify-center">
                                    <div className="w-4/5">
                                        <p className="pb-2 mt-8 font-semibold text-purple-600">Write about the photo</p>
                                        <button className="mt-3 text-sm px-5 font-semibold border-2 border-blue-600 rounded py-2 text-blue-600 roundedt">Instructions</button>
                                        <p href="#" className="text-blue-600 font-semibold text-sm mt-2 hover:text-blue-800 transition duration-300 ease-in-out">+1K sample questions</p>
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <Link className="w-4/5 pt-16 flex justify-around">
                                        <p className="text-blue-600 cursor-pointer hover:underline font-semibold">Go to course</p>
                                        <ArrowCircleRightIcon className="w-6 h-6 text-blue-600 hover:text-blue-700 cursor-pointer" />
                                    </Link>
                                </div>
                            </div>
                        </a>
                    </Link>
                </div>
            </div>
            <div className="flex justify-center">
                <div className="w-full 2xl:w-4/5 grid grid-cols-1 sm:grid-cols-4">
                    <Link to="" className="flex justify-center py-5">
                        <a onClick={(e) => user ? next9(e) : signInWithGoogle(e)} className=" mb-5 rounded-tr-3xl shadow hover:shadow-xl w-3/4">
                            <div className=" flex bg-cover bg-[url('https://images.tokopedia.net/blog-tokopedia-com/uploads/2021/03/Freepik2-1.jpg')] rounded-tr-3xl justify-center">
                                <div className="w-4/5">
                                    <p className="pt-32 text-transparent">Hola</p>
                                </div>
                            </div>
                    <div className="border-b-2 pb-4 border-x-2 border-blue-500">
                                <div className="flex justify-center">
                                    <div className="w-4/5">
                                        <p className="pb-2 mt-8 font-semibold text-purple-600">Speak about the photo</p>
                                        <button className="mt-3 text-sm px-5 font-semibold border-2 border-blue-600 rounded py-2 text-blue-600 roundedt">Instructions</button>
                                        <p href="#" className="text-blue-600 font-semibold text-sm mt-2 hover:text-blue-800 transition duration-300 ease-in-out">+1K sample questions</p>
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <Link className="w-4/5 pt-16 flex justify-around">
                                        <p className="text-blue-600 cursor-pointer hover:underline font-semibold">Go to course</p>
                                        <ArrowCircleRightIcon className="w-6 h-6 text-blue-600 hover:text-blue-700 cursor-pointer" />
                                    </Link>
                                </div>
                             </div>
                        </a>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default Practice;