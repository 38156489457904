import React, { useState, useEffect } from 'react';
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import _ from "lodash";
import { FaMicrophone } from "react-icons/fa6";
import { IoStop } from "react-icons/io5";
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import data from "../data/data_practice/read_aloud.json"

const Readal = ({ paid, setPaid, user}) => {
    const [isRecording, setIsRecording] = useState(false);
    const [aiAnalysisCount, setAiAnalysisCount] = useState(0);
    const [current, setCurrent] = useState(0)
    const [timer, setTimer] = useState(20);
    const [response, setResponse] = useState()
    const [text, setText] = useState("")

    useEffect(() => {
        const fetchUserData = async () => {
            if (user) {
                const db = getFirestore();
                const userRef = doc(db, 'paying_users', user.email);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setPaid(userDoc.data().paid || false);
                    setAiAnalysisCount(userDoc.data().aiAnalysisCount || 0);
                }
            }
        };

        fetchUserData();
    }, [user]);

    const handleFunctionality = () => {
        if (user && paid) {
            return true;
        } else if (!paid && aiAnalysisCount < 3 && current < 3) {
            return true;
        } else {
            alert("You have reached the limit of 5 AI analysis or are not on the first 5 prompts. Subscribe to get unlimited AI check.");
            return false;
        }
    };

    useEffect(() => {
        setTimer(20);

        const interval = setInterval(() => {
            setTimer(prevTimer => prevTimer > 0 ? prevTimer - 1 : 0);
        }, 1000);

        return () => clearInterval(interval);
    }, [current]);



    const startRecording = async () => {
        if (handleFunctionality()) {
            const speechConfig = sdk.SpeechConfig.fromSubscription("4e3c3d23d7cd43a4b7414e6d3f4da512", "eastus");
            speechConfig.speechRecognitionLanguage = "en-US";

            const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
            const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
            const referenceText = data.data[current].sentence;
            const pronunciationAssessmentConfig = new sdk.PronunciationAssessmentConfig(referenceText, sdk.PronunciationAssessmentGradingSystem.HundredMark, sdk.PronunciationAssessmentGranularity.Phoneme, true);
            pronunciationAssessmentConfig.applyTo(recognizer);

            recognizer.recognizing = (s, e) => {
                setText(e.result.text)
            };

            recognizer.recognized = (s, e) => {
                if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
                    var pronunciationResult = sdk.PronunciationAssessmentResult.fromResult(e.result);
                    console.log("Pronunciation assessment results: ", pronunciationResult);
                    setResponse(pronunciationResult);
                    console.log("hola: ", pronunciationResult)
                }
                else if (e.result.reason === sdk.ResultReason.NoMatch) {
                    console.log("NOMATCH: Speech could not be recognized.");
                }
            };

            recognizer.startContinuousRecognitionAsync(() => {
            },
                err => {
                    console.error(`ERROR: ${err}`);
                });

            setIsRecording(true);
            window.currentRecognizer = recognizer;
            const db = getFirestore();
            const userRef = doc(db, 'paying_users', user.email);
            await setDoc(userRef, { aiAnalysisCount_read_aloud: aiAnalysisCount + 1 }, { merge: true });
            setAiAnalysisCount(prevCount => prevCount + 1);
        }

    };

    const stopRecording = () => {
        if (window.currentRecognizer) {
            window.currentRecognizer.stopContinuousRecognitionAsync(() => {
                setIsRecording(false);
                window.currentRecognizer = null;
            },
                err => {
                    console.error(`ERROR: ${err}`);
                });
        }
    };

    useEffect(() => {
        console.log(response);
    }, [response]); 

    const handleRecordingToggle = () => {
        if (isRecording) {
            stopRecording();
        } else {
            startRecording();
        }
    };

    const handleNextSection = async () => {
        if (current === data.data.length - 1) {
            const db = getFirestore();
            const userRef = doc(db, 'paying_users', user.email);
            await setDoc(userRef, { aiAnalysisCount_read_aloud: 0 }, { merge: true });
            setAiAnalysisCount(0);
        }
        setCurrent(current + 1)
        setResponse("")
        setText("")
    }

    return (
        <div className='py-20'>
        <div className='flex justify-center'>
                <div className='w-5/6 sm:w-3/5 rounded border border-gray-200 shadow-2xl p-4'>
                    <div id="60 py-6 seconds timer here">
                        <p className="text-lg pl-6 pt-6 font-semibold text-blue-800">{timer > 0 ? `${timer} seconds left` : "Time's up!"}</p>
                    </div>
                    <div className="flex justify-center">
                        <p className="text-xl text-blue-950 pb-8 font-semibold py-12 uppercase">Record yourself saying the statement below.</p>
                    </div>
                    <div className="py-4">
                        <div className="grid mt-4 grid-cols-1 sm:grid-cols-2">
                            <div className="flex justify-center">
                                <div className="flex mb-12 items-center">
                                    <button
                                        className="bg-blue-500 hover:bg-blue-700 rounded p-5 rounded-full font-semibold"
                                        onClick={handleRecordingToggle}
                                    >
                                        {isRecording ? <>
                                            <IoStop className=" text-red-500 hover:text-red-600 w-8 h-auto" />
                                        </> : <> <FaMicrophone className=" w-8 text-white hover:text-gray-100 h-auto" /> </>}
                                    </button>
                                </div>
                            </div>
                            <div className="mb-4 text-gray-700 mt-6 sm:mt-0">{data.data[current].sentence}</div>
                        </div>
                    </div>
                    <div className='flex justify-center'>
                        <p>{text}</p>
                    </div>
                    {response &&
                    <>
                        <div className='flex justify-center'>
                            <div className='w-5/6 py-12 sm:flex'>
                                <div className='w-full sm:w-2/5'>
                                    <p className='text-gray-600'> Confidence: {response?.privPronJson?.Confidence} </p>
                                    <p className='text-gray-800 font-semibold pb-3'>Pronunciation Score: {response?.privPronJson?.PronunciationAssessment?.PronScore}</p>
                                    <div className='flex justify-center'>
                                        <svg width="100" height="100" viewBox="0 0 36 36" className="circular-chart">
                                            <path className="circle-bg"
                                                d="M18 2.0845
                                    a 15.9155 15.9155 0 0 1 0 31.831
                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                                fill="none" stroke="#eee" strokeWidth="4" />
                                            <path className="circle"
                                                d="M18 2.0845
                                        a 15.9155 15.9155 0 0 1 0 31.831
                                        a 15.9155 15.9155 0 0 1 0 -31.831"
                                                fill="none" stroke="#00cc99" strokeWidth="4" strokeDasharray={`${response?.privPronJson?.PronunciationAssessment?.PronScore}, 100`} />
                                        </svg>
                                    </div>
                                </div>
                                <div className='w-full sm:w-3/5'>
                                    <div className='grid py-6 py-12 grid-cols-1 sm:grid-cols-2'>
                                        <div className='px-2'>
                                            <div className='flex w-full justify-between'>
                                                <p className='text-gray-600'>Fluency Score: </p>
                                                <p className='text-gray-600'>{response?.privPronJson?.PronunciationAssessment?.FluencyScore}/100</p>
                                            </div>
                                            <div style={{ width: '100%', backgroundColor: '#eee', height: '15px' }}>
                                                <div className='rounded' style={{ width: `${response?.privPronJson?.PronunciationAssessment?.FluencyScore}%`, backgroundColor: 'blue', height: '100%' }}></div>
                                            </div>
                                            <div className='flex w-full pt-4 justify-between'>
                                                <p className='text-gray-600'>Completeness Score: </p>
                                                <p className='text-gray-600'>{response?.privPronJson?.PronunciationAssessment?.CompletenessScore}/100</p>
                                            </div>
                                            <div style={{ width: '100%', backgroundColor: '#eee', height: '15px' }}>
                                                <div className='rounded' style={{ width: `${response?.privPronJson?.PronunciationAssessment?.CompletenessScore}%`, backgroundColor: 'blue', height: '100%' }}></div>
                                            </div>
                                        </div>
                                        <div className='px-2'>
                                            <div className='flex w-full justify-between'>
                                                <p className='text-gray-600'>Accuracy Score: </p>
                                                <p className='text-gray-600'>{response?.privPronJson?.PronunciationAssessment?.AccuracyScore}/100</p>
                                            </div>
                                            <div style={{ width: '100%', backgroundColor: '#eee', height: '15px' }}>
                                                <div className='rounded' style={{ width: `${response?.privPronJson?.PronunciationAssessment?.AccuracyScore}%`, backgroundColor: 'blue', height: '100%' }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-center'>
                            <div className='w-4/5'>
                                {response?.privPronJson?.Words?.map((word, index) => (
                                    <a key={index} className="mb-2 inline-flex">
                                        <p className={`${word?.PronunciationAssessment?.ErrorType === "Mispronunciation" ? "text-red-500" : word?.PronunciationAssessment?.ErrorType === "Insertion" ? "text-blue-600" : "text-gray-500"}`}>
                                            {word.Word}<a className='text-white'>{"h"}</a>
                                        </p>
                                    </a>
                                ))}
                            </div>
                        </div>
                        <div className="py-3 flex justify-center">
                            <div class='w-4/5'>
                                <p className='font-medium'><div class="inline-block w-4 h-4 bg-red-500 mr-2"></div>Mispronunciation</p>
                                <p className='pt-2 font-medium'><div class="inline-block w-4 h-4 bg-blue-500 mr-2"></div>Insertion</p>
                            </div>

                        </div>
                    </>
                    }
                    <div className='flex justify-end'>
                        <button
                            onClick={handleNextSection}
                            className="bg-blue-800 hover:bg-blue-700 rounded text-yellow-300 px-5 py-2 font-semibold mt-4"
                        >
                            Next
                        </button>
                    </div>
</div>
        </div>
            <div className='flex pt-8 justify-center'>
            </div>
        </div>
    );
};

export default Readal;
