import React, { useState } from "react";
import { FaFacebook, FaYoutube, FaTiktok, FaLinkedin, FaGithub } from "react-icons/fa";
import { getFirestore, collection, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import {db} from "../firebase"

const Footer = () => {


    const emailRef = collection(db, "emails");

    const [correo, setCorreo] = useState("")

    const agregar = async (e) => {
        e.preventDefault();
        await addDoc(emailRef, {
            correo: correo,
            timestamp: serverTimestamp()
        });
        alert("Subscribed");
        setCorreo("")
    }

    return (
        <div className="px-5 sm:px-0 footer">
            <div className="flex justify-center">
                <div className="w-11/12 sm:w-5/6">
                    <div className="w-full sm:w-1/2 sm:flex">
                        <div className="w-2/5 sm:w-1/5 pt-24 pb-8">
                            <p className="text-lg font-medium text-gray-800">Follow us</p>
                        </div>
                        <div className="w-3/5 sm:w-1/2 flex items-center space-x-6 sm:pt-24 pb-8">
                            <a href="https://www.linkedin.com/company/konlap" target="_blank"><FaLinkedin className="text-gray-700 w-6 h-6 hover:text-gray-800 cursor-pointer" /></a>
                            <a href="https://www.youtube.com/@konlap4610" target="_blank"><FaYoutube className="text-gray-700 w-6 h-6 hover:text-gray-800 cursor-pointer" /></a>
                            <a href="https://web.facebook.com/konlap/?_rdc=1&_rdr" target="_blank"><FaFacebook className="text-gray-700 w-6 h-6 hover:text-gray-800 cursor-pointer" /></a>
                        </div>
                    </div>
                    <hr className="text-gray-300 py-6"></hr>
                    <form onSubmit={(e) => { agregar(e) }} className="flex pb-8">
                        <input
                            value={correo}
                            onChange={(event) => { setCorreo(event.target.value) }}
                            placeholder="Enter your email address"
                            className="p-2 w-2/3 sm:w-1/2 border rounded outline-none border-gray-400" />
                        <button
                            type="submit"
                            className="px-3 py-3 ml-8 rounded-xl bg-blue-100 text-blue-700 font-semibold hover:shadow-xl"
                        >
                            Subscribe
                        </button>
                    </form>
                    <div className="pb-6">
                        <p className="text-gray-400 text-sm">I accept EduX's Terms and Conditions and acknowledge that my information will be used in accordance with EduX's Privacy Policy.</p>
                    </div>
                    <div className="pb-12 pt-3 flex space-x-4 sm:space-x-8">
                        <a href="https://www.linkedin.com/company/konlap" target="_blank" className="text-gray-500 font-semibold cursor-pointer">EduX</a>
                        <p href="https://www.linkedin.com/company/konlap" target="_blank" className="text-gray-500 font-semibold cursor-pointer">Terms and conditions</p>
                        <p href="https://www.linkedin.com/company/konlap" target="_blank" className="text-gray-500 font-semibold cursor-pointer">Privacy policy</p>
                    </div>
                </div>
            </div>
        </div>
        );
    };

export default Footer;