import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Line } from 'react-chartjs-2';
import Report from "./report";
import { FaUser, FaIdBadge, FaThumbsUp, FaThumbsDown } from 'react-icons/fa';

const Profile = ({ user, scorep, scoresss, scoresls, scoresl, scores }) => {

    return (
        <div className="flex justify-center bg-gray-100 min-h-screen">
            <div className="w-11/12 pt-32 sm:w-4/5 bg-white p-8 rounded-lg shadow-lg">
                <div className="flex items-center pb-12">
                    <img src={user?.photoURL} alt="Profile" className="w-16 h-16 rounded-full mr-4" />
                    <p className="text-2xl font-semibold text-blue-600">Reach higher with EduX AI</p>
                </div>
                <div className="pb-6">
                    <p className="text-xl pb-5 font-semibold flex items-center"><FaUser className="mr-2 text-blue-600" /> Candidate Name: {user?.displayName}</p>
                    <p className="text-xl font-semibold flex items-center"><FaIdBadge className="mr-2 text-blue-600" /> ID: {user.uid}</p>
                </div>

                <Report user={user} scorep={scorep} scoresss={scoresss} scoresls={scoresls} scoresl={scoresl} scores={scores} />
            </div>
        </div>
    );
};

export default Profile;
