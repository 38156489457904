import React, { useState, useEffect, useRef } from "react"
import { RiSpeakLine } from "react-icons/ri";
import { HiOutlineSpeakerWave } from "react-icons/hi2"
import data from "../data/data_practice/listentype.json"

const Listentype = ({ paid, setPaid, user }) => {
    const audioRef = useRef(null);
    const [aiAnalysisCount, setAiAnalysisCount] = useState(0);
    const playAudio = () => {
        if (audioRef.current) {
            audioRef.current.play();
        }
    };
    const [showAnswer, setShowAnswer] = useState(false);
    const [userInput, setUserInput] = useState("");
    const [current, setCurrent] = useState(0);
    const [timer, setTimer] = useState(60); 

    const handleNext = () => {
        setShowAnswer(false)
        setTimer(60);
        setCurrent(current + 1);
        setUserInput('');
    };


    
    useEffect(() => {
        setTimer(60);

        const interval = setInterval(() => {
            setTimer(prevTimer => prevTimer > 0 ? prevTimer - 1 : 0);
        }, 1000);

        return () => clearInterval(interval);
    }, [current]);

    const Submit = () => {
        setShowAnswer(true)
    }


    return (
<div className="flex justify-center py-20">
<div className="w-5/6 sm:w-3/5 rounded border shadow-2xl border-gray-200 p-4">
                <div id="60 py-6 seconds timer here">
                    <p className="text-lg pl-6 pt-6 font-semibold text-blue-800">{timer > 0 ? `${timer} seconds left` : "Time's up!"}</p>
                </div>
                <div className="p-4">
                    <div className="flex pb-6 justify-center">
                        <p className="text-gray-800 text-lg font-semibold">Type the statement that you hear</p>
                    </div>
                    <div className="block sm:flex">
                        <div className="w-full sm:w-2/5">
                            <div className="flex mt-6 justify-center">
                                <div className="flex items-center">
                                    <div onClick={playAudio} className="p-8 cursor-pointer hover:bg-blue-700 bg-blue-800 flex rounded-full">
                                        <audio src={data.data[current].src} ref={audioRef} className="hidden" />
                                        <button className="">
                                            <RiSpeakLine className="text-yellow-300 w-8 h-8" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-center">
                                <p className="text-gray-700 mt-2 mb-4 sm:mb-0 font-semibold">Click to listen</p>
                            </div>
                        </div>
                        <div className="flex w-full sm:w-3/5 justify-center sm:justify-start">
                            <textarea
                                type="text"
                                value={userInput}
                                onChange={(e) => setUserInput(e.target.value)}
                                className="border-2 font-semibold border-blue-800 text-gray-700 rounded outline-none p-2 w-full sm:w-3/4"
                                rows={6}
                                placeholder="Your response"
                            />
                        </div>
                    </div>
                    {showAnswer &&
                        <div className="flex justify-center">
                            <div className="w-full sm:w-3/4 rounded border shadow-lg border-gray-200 p-4 my-10">
                                <p className="text-lg font-semibold text-blue-800 mt-4 mb-2">Actual answer</p>
                                <p className="text-gray-600 mb-4">{data.data[current].prompt}</p>
                            </div>
                        </div>
                    }
                    <div className="flex mt-4 justify-end">
                        <button
                            onClick={Submit}
                            className="bg-blue-800 hover:bg-blue-700 mr-12 rounded text-yellow-300 px-5 py-2 font-semibold mt-4"
                        >
                            Submit
                        </button>
                        <button
                            onClick={handleNext}
                            className="bg-blue-800 hover:bg-blue-700 rounded text-yellow-300 px-5 py-2 font-semibold mt-4"
                        >
                            Next
                        </button>
                    </div>
                </div>
</div>
</div>
    )
}

export default Listentype;