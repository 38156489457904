import React, { useEffect, useState, useRef } from "react";
import blankSpaces from "../data/data_practice/blankspaces.json";

const Fillblank = () => {
    const [timer, setTimer] = useState(60);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [userAnswers, setUserAnswers] = useState(Array(blankSpaces[currentIndex].missing_letters.length).fill(""));
    const [showActualWords, setShowActualWords] = useState(false);
    const inputRefs = useRef([]);

    useEffect(() => {
        setTimer(60);
        const interval = setInterval(() => {
            setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
        }, 1000);
        return () => clearInterval(interval);
    }, [currentIndex]);

    useEffect(() => {
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, [currentIndex]);

    const handleInputChange = (index, value) => {
        const newAnswers = [...userAnswers];
        newAnswers[index] = value;
        setUserAnswers(newAnswers);

        if (value !== "" && index < blankSpaces[currentIndex].missing_letters.length - 1) {
            const nextIndex = index + 1;
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex].focus();
            }
        }
    };

    const handleSubmit = () => {
        setShowActualWords(true);
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % blankSpaces.length);
        setUserAnswers(Array(blankSpaces[(currentIndex + 1) % blankSpaces.length].missing_letters.length).fill(""));
        setShowActualWords(false);
    };


    return (
        <>
            <div className="flex justify-center py-20">
                <div className="w-11/12 sm:w-5/6 2xl:w-3/5 rounded border shadow-2xl border-gray-200 py-8 px-4">
                    <div id="timer">
                        <p className="text-lg pl-6 pt-6 font-semibold text-blue-800">
                            {timer > 0 ? `${timer} seconds left` : "Time's up!"}
                        </p>
                    </div>
                    <div className="flex pb-2 pt-4 justify-center">
                        <p className="text-gray-800 text-lg font-semibold">Type the missing letter to complete the text below</p>
                    </div>
                    <div className="flex justify-center">
                        <div className="w-11/12 sm:w-3/4 mt-8">
                            {blankSpaces[currentIndex].prompt.split(/(\b\w*_+\b)/g).map((part, index) => {
                                if (part.includes("_")) {
                                    const missingLetters = part.match(/_/g).length;
                                    let currentMissingLetterIndex = part.indexOf("_");
                                    return (
                                        <React.Fragment key={index}>
                                            {part.slice(0, currentMissingLetterIndex)}
                                            {[...Array(missingLetters)].map((_, i) => {
                                                const missingLetterIndex = currentMissingLetterIndex;
                                                currentMissingLetterIndex = blankSpaces[currentIndex].missing_letters.indexOf("_", currentMissingLetterIndex + 1);
                                                return (
                                                    <input
                                                        key={`${index}-${missingLetterIndex}`}
                                                        ref={(el) => (inputRefs.current[missingLetterIndex] = el)}
                                                        type="text"
                                                        maxLength="1"
                                                        value={userAnswers[missingLetterIndex]}
                                                        onChange={(e) => handleInputChange(missingLetterIndex, e.target.value)}
                                                        className={`w-6 h-6 text-center border-t border-b border-l border-r ${i === 0 ? "rounded-tl-md rounded-bl-md" : ""
                                                            } ${i === missingLetters - 1 ? "rounded-tr-md rounded-br-md" : ""} ${showActualWords
                                                                ? userAnswers[missingLetterIndex] === blankSpaces[currentIndex].missing_letters[missingLetterIndex]
                                                                    ? "border-green-500"
                                                                    : "border-red-500"
                                                                : "border-gray-300"
                                                            } focus:outline-none focus:ring-2 focus:ring-blue-500`}
                                                        disabled={showActualWords}
                                                    />
                                                );
                                            })}
                                            {part.slice(currentMissingLetterIndex)}
                                        </React.Fragment>
                                    );
                                }
                                return <span key={index}>{part}</span>;
                            })}
                        </div>
                    </div>
                    <div className="flex mt-4 justify-end">
                        <button
                            className="bg-blue-800 hover:bg-blue-700 mr-12 rounded text-yellow-300 px-5 py-2 font-semibold mt-4"
                            onClick={handleSubmit}
                            disabled={showActualWords}
                        >
                            Submit
                        </button>
                        <button
                            className="bg-blue-800 hover:bg-blue-700 rounded text-yellow-300 px-5 py-2 font-semibold mt-4"
                            onClick={handleNext}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Fillblank;