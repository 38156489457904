import React, { useState, useEffect } from "react";
import data from "../data/data_practice/writephoto.json"
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { Configuration, OpenAIApi } from "azure-openai";

const openai = new OpenAIApi(
    new Configuration({
        azure: {
            apiKey: "c0086bc08dc64d3b8d8c89a710000e6e",
            endpoint: "https://ysis.openai.azure.com/",
            deploymentName: "luia",
        }
    }),
);

const Writephoto = ({ apiResponse, paid, setPaid, user, setApiResponse, advancedWordsList, score, feedback }) => {
   
    const [showAnswer, setShowAnswer] = useState(false);
    const [current, setCurrent] = useState(0);
    const [aiAnalysisCount, setAiAnalysisCount] = useState(0);
    const [timer, setTimer] = useState(60);
    const [userResponse, setUserResponse] = useState("");

    const handleNext = async () => {
        if (current === data.data.length - 1) {
            const db = getFirestore();
            const userRef = doc(db, 'paying_users', user?.email);
            await setDoc(userRef, { aiAnalysisCount_write_about_the_photo: 0 }, { merge: true });
            setAiAnalysisCount(0);
        }
        setShowAnswer(false)
            setCurrent(current + 1);
            setUserResponse('');
            setTimer(60);
            setUserResponse("")
    };

    useEffect(() => {
        const fetchUserData = async () => {
            if (user) {
                const db = getFirestore();
                const userRef = doc(db, 'paying_users', user?.email);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setPaid(userDoc.data().paid || false);
                    setAiAnalysisCount(userDoc.data().aiAnalysisCount || 0);
                }
            }
        };

        fetchUserData();
    }, [user]);

    const handleFunctionality = () => {
        if (user && paid) {
            return true;
        } else if (!paid && aiAnalysisCount < 3 && current < 3) {
            return true;
        } else {
            alert("Subscribe to get unlimited access");
            return false;
        }
    };


    useEffect(() => {
        setTimer(60);

        const interval = setInterval(() => {
            setTimer(prevTimer => prevTimer > 0 ? prevTimer - 1 : 0);
        }, 1000);

        return () => clearInterval(interval);
    }, [current]);

    const instrucciones = `This is a sample full Duolingo English Test "Write about the photo" section. Generate detailed feedback. This is an approximate description of the image: ${data.data[current].description} and this is the user answer: ${userResponse}. The user should consider:  What is happening in this photo?  Who is doing what? Where are they doing it?  When are they doing it? Why are they doing it? Do not be strict because the user has only 90 seconds to answer.}`

    const sendData = async () => {
        const response = await openai.createChatCompletion({
            "messages": [
                { "role": "system", "content": "You assess the writing skills of a candidate" },
                { "role": "user", "content": `${instrucciones}` }
            ],
            "temperature": 0
        });
        console.log(response.data.choices[0].message.content);
        setApiResponse(response.data.choices[0].message.content)
        setShowAnswer(true)
    }

    const Submit = async () => {
        if (handleFunctionality()) {
            sendData()
            setShowAnswer(true)
            const db = getFirestore();
            const userRef = doc(db, 'paying_users', user?.email);
            await setDoc(userRef, { aiAnalysisCount_write_about_the_photo: aiAnalysisCount + 1 }, { merge: true });
            setAiAnalysisCount(prevCount => prevCount + 1);
        }
    }


    return (
        <div className="flex justify-center py-20">
<div className="w-11/12 sm:w-3/5 rounded border shadow-2xl border-gray-200 p-4">
                <div className="px-4">
                    <div className='flex py-8'>
                        <p className='font-semibold text-lg pl-6 text-blue-800'>{timer > 0 ? `${timer} seconds left` : "Time's up!"}</p>
                    </div>
                    <div className="flex pb-6 justify-center">
                        <p className="text-gray-800 text-lg font-semibold">Write a description of the image below for 1 minute</p>
                    </div>
                    <div className="sm:flex mt-4">
                        <div className="flex w-full sm:w-2/5 justify-center">
                            <img src={data.data[current].photoURL} alt="Duolingo Test" className="mb-4 rounded w-7/12 h-auto" />
                        </div>
                        <div className="flex justify-center w-full sm:w-3/5 pr-5">
                            <textarea
                                value={userResponse}
                                onChange={(e) => setUserResponse(e.target.value)
                                }
                                className="border-2 p-2 rounded border-blue-500 outline-none w-full h-40"
                                placeholder="Your response"
                            />
                        </div>
                    </div>
                    {showAnswer &&
                        <div className="flex justify-center">
                            <div className="w-full rounded border shadow-lg border-gray-200 p-4 my-10">
                                <p className="text-lg font-semibold text-blue-800 mt-4 mb-2">Sample answer</p>
                                <p className="text-gray-600 mb-4">{data.data[current].description}</p>
                            </div>
                        </div>
                    }
                    {showAnswer &&
                        <>
                            <div className="flex justify-center">
                                <div className="w-full rounded border shadow-lg border-gray-200 p-4 my-10">
                                    <p className="text-xl font-semibold text-blue-800 mt-4 mb-2">AI analysis</p>
                                        <a className="text-gray-600 ml-1 font-medium">{apiResponse}</a>
                                </div>
                            </div>
                        </>}
                    <div className="flex mt-4 justify-end">
                        <button
                            onClick={Submit}
                            className="bg-blue-800 hover:bg-blue-700 mr-12 rounded text-yellow-300 px-5 py-2 font-semibold mt-4"
                        >
                            Submit
                        </button>
                        <button
                            onClick={handleNext}
                            className="bg-blue-800 hover:bg-blue-700 rounded text-yellow-300 px-5 py-2 font-semibold mt-4"
                        >
                            Next
                        </button>
                    </div>
                </div>
</div>
</div>
    )
}

export default Writephoto;
