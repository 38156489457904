import React, { useState } from "react";

const WriteAboutThePhoto = ({ data, handleNextSection, userResponse, setUserResponse }) => {
    const [current, setCurrent] = useState(0);
    const [currentResponse, setCurrentResponse] = useState('');

    const handleNext = () => {
        if (current < data.length - 1) {
            setUserResponse(prevResponses => [...prevResponses, currentResponse]);
            setCurrent(current + 1);
            setCurrentResponse('');
        } else {
            setUserResponse(prevResponses => [...prevResponses, currentResponse]);
            handleNextSection();
        }
    };

    return (
        <div className="px-4">
            <div className="flex pt-12 pb-6 justify-center">
                <p className="text-gray-800 uppercase text-lg font-semibold">Write a description of the image below for 1 minute</p>
            </div>
            <div className="sm:flex mt-4">
                <div className="flex w-full sm:w-2/5 justify-center">
                    <img src={data[current].photoURL} alt="Duolingo Test" className="mb-4 rounded w-7/12 h-auto" />
                </div>
                <div className="flex justify-center w-full sm:w-3/5 pr-5">
                    <textarea
                        value={currentResponse}
                        onChange={(e) => setCurrentResponse(e.target.value)}
                        className="border p-2 rounded border-gray-300 outline-none w-full h-40"
                        placeholder="Your response"
                    />
                </div>
            </div>
            <div className="flex mt-4 justify-end">
                <button
                    onClick={handleNext}
                    className="bg-blue-800 hover:bg-blue-700 rounded text-yellow-300 px-5 py-2 font-semibold mt-4"
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default WriteAboutThePhoto;
