import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";

const Posts = (props) => {

    const { id } = useParams();
    const publications = props.publications;
    const publication = publications?.find(pub => pub.id === id);

    if (!publication) {
        return <div className="flex justify-center pt-32">Loading</div>;
    }


    return(
<>
            <div className="flex justify-center">
                <div className="w-11/12 sm:w-3/4 grid grid-cols-1 pb-6 pt-20">
                <div className="flex sm:px-40 justify-center">
                        <p className="text-gray-900 pb-12 capitalize font-bold text-3xl mb-3">{publication.title}</p>
                </div>
                    <div className="flex justify-center">
                        <img className="w-5/6 h-auto rounded" src={publication.image} />
                    </div>
                    <div className="flex items-center">
                    <div>
                            <p className="text-gray-600 pt-12 text-lg hover:text-gray-700">{publication.Description}</p>
                    </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-center">
                <hr className="w-11/12 pb-6 sm:w-3/4 text-gray-300">
                </hr>
            </div>
            <div className="flex justify-center">
                <div className="w-11/12 sm:w-3/4 pt-4 pb-6">
                    <p className="text-gray-500 font-medium pb-6">Published on: {publication.date.toDate().toLocaleString()}</p>
                </div>
            </div>
            <div className="flex justify-center">
                <hr className="w-11/12 sm:w-3/4 text-gray-300">
                </hr>
            </div>
            <div className="flex justify-center">
            <div className="w-11/12 sm:w-3/4 pb-32 pt-6">
                    <p className="text-gray-600 text-justify " dangerouslySetInnerHTML={{ __html: publication.maintext }}></p>
            </div>
            </div>
</>
    )
}

export default Posts;