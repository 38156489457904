import React, {useState, useEffect} from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { Configuration, OpenAIApi } from "azure-openai";
import DeleteAccount from "./components/privacy"
import Contact from "./components/contact";
import { signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from "./firebase";
import VideoRecording from "./components/practice/videoRecorder";
import Profile from "./components/profile";
import SpeakingLab from "./components/practice/speakinglab";
import Fillblank from "./components/practice/fillblank";
import Pricing from "./components/pricing";
import UserSession from "./userssion"
import Home from "./components/home";
import Blog from "./components/blog";
import ReactGA from "react-ga"
import Posts from "./components/posts";
import Result from "./components/test/results";
import { collection, getDocs } from 'firebase/firestore';
import { db } from "./firebase";
import { useAuthState } from 'react-firebase-hooks/auth';
import Det from "./components/det";
import Footer from "./components/footer";
import Header from "./components/header";
import ListenAndType from './components/test/ListenAndType';
import ReadAloud from './components/test/ReadAloud';
import WriteAboutThePhoto from './components/test/WriteAboutThePhoto';
import SpeakAboutThePhoto from "./components/test/speak";
import ListenThenSpeak from "./components/test/listenspeak";
import WritingSample from "./components/test/writingsample";
import ReadThenWrite from "./components/test/write";
import ReadThenSpeak from "./components/test/readspeak";
import SpeakingSample from './components/test/speakingsample';
import Test from "./components/test";
import Signup from "./components/signup";
import ScrollToTop from "./scroll"
import DuolingoEnglishTestSample from "./components/data/data.json";
import ReadThenSelect from "./components/test/select";
import Practice from "./components/practice/practice";
import Listenspeak from "./components/practice/listenspeak"
import Listentype from "./components/practice/listentype";
import Readal from "./components/practice/readal";
import Readselect from "./components/practice/readselect";
import Readspeak from "./components/practice/readspeak";
import Read_Write from "./components/practice/readwrite";
import Speaking from "./components/practice/speakings";
import Speakphoto from "./components/practice/speakphoto";
import Writephoto from "./components/practice/writephoto";

const TRACKING_ID = "G-ZJZTM7TZPL"
ReactGA.initialize(TRACKING_ID)

const openai = new OpenAIApi(
  new Configuration({
    azure: {
      apiKey: "c0086bc08dc64d3b8d8c89a710000e6e",
      endpoint: "https://ysis.openai.azure.com/",
      deploymentName: "luia",
    }
  }),
);

const initialOptions = {
  "client-id": "AWa_g4N5yfNT9FgV6Kdhr8AX905OZBRMkYs4YK7uqNJlIOP9cmrCAu1nlUDdUmCDgSFpg4ME3gUDLGTJ",
  currency: "USD",
  intent: "capture",
};

function App() {
  const [paid, setPaid] = useState(false)
  const [currentSection, setCurrentSection] = useState(0);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  const handleNextSection = () => {
    if (currentSection < sections.length - 1) {
      setCurrentSection(currentSection + 1);
    } else {

    }
  };

  useEffect(() => {
    document.title = "EduX: Full Duolingo English full tests with AI"
  }, [])

  const [publications, setPublications] = useState([]);
  const publicationsRef = collection(db, "posts");
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getPublications = async () => {
      setLoading(true)
      const data = await getDocs(publicationsRef);
      setPublications(data?.docs?.map((doc) => ({ ...doc.data(), id: doc.id })));
      console.log(publications);
      console.log("Listo")
      setLoading(false)
    }

    getPublications();
    console.log(publications);
  }, [])

  const [userInput, setUserInput] = useState([]);
  const [realAloudRecord, setRealAloudRecord] = useState([]);
  const [userResponse, setUserResponse] = useState([]);
  const [speakPhoto, setSpeakPhoto] = useState([]);
  const [listenSpeak, setListenSpeak] = useState([]);
  const [speakSample, setSpeakSample] = useState([]);
  const [writeSample, setWriteSample] = useState([]);
  const [read, setRead] = useState([]);
  const [readSpeak, setReadSpeak] = useState([]);

const instruct = `This is a sample full Duolingo English Test. 
For the Listen and type section:
The first prompt is: ${DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.ListenAndType[0].transcription} and the user response is: ${userInput[0]}

The second prompt is: ${DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.ListenAndType[1].transcription} and the user response is: ${userInput[1]}

The third prompt is: ${DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.ListenAndType[2].transcription} and the user response is:${userInput[2]}

The fourth prompt is: ${DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.ListenAndType[3].transcription} and the user response is: ${userInput[3]}

For the Read aloud section:

The first prompt is: ${DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.ReadAloud[0].textToRead} and the user spoken words are: ${realAloudRecord[0]}

The second prompt is: ${DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.ReadAloud[1].textToRead} and the user spoken words are: ${realAloudRecord[1]}

For the Write about the photo section the user should describe the image in his own words:

The first image approximate description is: ${DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.WriteAboutThePhoto[0].description} and the user description is: ${userResponse[0]}

The second image approximate description is: ${DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.WriteAboutThePhoto[1].description} and the user description is: ${userResponse[1]}

The third image description approximate is: ${DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.WriteAboutThePhoto[2].description} and the user description is: ${userResponse[2]}

For the Speak about the photo section the user should speak and describe the photo:

The first image approximate description is: ${DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.SpeakAboutThePhoto[0].sample_answer} and the user description is: ${speakPhoto[0]}

The second image approximate description is: ${DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.SpeakAboutThePhoto[1].sample_answer} and the user description is: ${speakPhoto[1]}

The third image approximate description is: ${DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.SpeakAboutThePhoto[2].sample_answer} and the user description is: ${speakPhoto[2]}

For the Listen then speak section. The user listens the prompt and then answers it in 50 words or more:

The first prompt is: ${DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.ListenThenSpeak[0].text} and the user answer is: ${listenSpeak[0]}

The second prompt is: ${DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.ListenThenSpeak[1].text} and the user answer is: ${listenSpeak[1]}

For the Speaking sample section The user reads the prompt and then answers it in 50 words or more:

The prompt is: ${DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.SpeakingSample[0].prompt} and the user answer is: ${speakSample[0]}

For the Writing sample section The user reads the prompt and then answers it in 50 words or more:

The prompt is: ${DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.WritingSample[0].prompt} and the user answer is: ${writeSample[0]}

For the Read then write section The user reads the prompts and then answers them in 50 words or more each one:

The first prompt is: ${DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.ReadThenWrite[0].readingText} and the user answer is:  ${read[0]}

The second prompt is: ${DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.ReadThenWrite[1].readingText} and the user answer is:  ${read[1]}

The third prompt is: ${DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.ReadThenWrite[2].readingText} and the user answer is:  ${read[2]}

The fourth prompt is: ${DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.ReadThenWrite[3].readingText} and the user answer is:  ${read[3]}

For the Read then speak section. The user reads the prompts and then answers them in 50 words or more each one:

The first prompt is: ${DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.ReadThenSpeak[0].readingText} and the user answer is: ${readSpeak[0]}

The second prompt is: ${DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.ReadThenSpeak[1].readingText} and the user answer is: ${readSpeak[1]}
  
Use this format to give the user a score {data=["global_score":"","comprehension":"","conversation":"","literacy":"","production":"","feedback": ""]. The global_score is the overall score and the subscores are: comprehension, conversation, literacy and production, and everyone of them go from 10 to 160, including the global_score. In the feedback describe what sections the user needs to improve and how he can do it. If the data or answers are incomplete then tell me which sections are incomplete. No matter if some sections are empty you should give a global score, subscores and feedback. Be positive and try to give estimated scores in the high range of the user capabilities.}`

  const [detResult, setDetResult] = useState("")

  const [loadingResult, setLoadingResult] = useState(false)
  const [showScores, setShowScores] = useState(false)

  const assesDET = async () => {
    setLoadingResult(true)
    const response = await openai.createChatCompletion({
      "messages": [
        { "role": "system", "content": "You assess the skills of a candidate in the Duolingo English Test" },
        { "role": "user", "content": `${instruct}` }
      ],
      "temperature": 0
    });
    console.log(response.data.choices[0].message.content);
    setDetResult(response.data.choices[0].message.content)
    setLoadingResult(false)
    setShowScores(true)
  }

  const sections = [
    <ListenAndType userInput={userInput} setUserInput={setUserInput} handleNextSection={handleNextSection} data={DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.ListenAndType} />,
    <ReadAloud realAloudRecord={realAloudRecord} setRealAloudRecord={setRealAloudRecord} handleNextSection={handleNextSection} data={DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.ReadAloud} />,
    <WriteAboutThePhoto userResponse={userResponse} setUserResponse={setUserResponse} handleNextSection={handleNextSection} data={DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.WriteAboutThePhoto} />,
    <SpeakAboutThePhoto speakPhoto={speakPhoto} setSpeakPhoto={setSpeakPhoto} handleNextSection={handleNextSection} data={DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.SpeakAboutThePhoto} />,
    <ListenThenSpeak setListenSpeak={setListenSpeak} handleNextSection={handleNextSection} data={DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.ListenThenSpeak} />,
    <SpeakingSample setSpeakSample={setSpeakSample} handleNextSection={handleNextSection} data={DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.SpeakingSample} />,
    <WritingSample writeSample={writeSample} setWriteSample={setWriteSample} handleNextSection={handleNextSection} data={DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.WritingSample} />,
    <ReadThenWrite read={read} setRead={setRead} handleNextSection={handleNextSection} data={DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.ReadThenWrite} />,
    <ReadThenSpeak setReadSpeak={setReadSpeak} handleNextSection={handleNextSection} data={DuolingoEnglishTestSample.DuolingoEnglishTestSample.sections.ReadThenSpeak} />,
    <Result showScores={showScores} loadingResult={loadingResult} detResult={detResult} assesDET={assesDET} />
  ];

  useEffect(() => {
    console.log(DuolingoEnglishTestSample)
  })

  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    if (!isAuthenticated) {
      setIsOpen(!isOpen);
    }
  };


  const [apiResponse, setApiResponse] = useState("");
  const advancedWordsListMatch = apiResponse?.match(/"advanced_words_list":\s*"(.*?)"/);
  const scoreMatch = apiResponse?.match(/"score":"(\d+)"/);
  const literacyMatch = apiResponse?.match(/"literacy":"(\d+)"/);
  const productionMatch = apiResponse?.match(/"production":"(\d+)"/);
  const feedbackMatch = apiResponse?.match(/"feedback":\s*"(.*?)"/);

  const advancedWordsList = advancedWordsListMatch ? advancedWordsListMatch[1] : "Not available";
  let score = scoreMatch ? scoreMatch[1] : "Not available";
  const feedback = feedbackMatch ? feedbackMatch[1] : "Not available";
  const literacy = literacyMatch ? literacyMatch[1] : "Not available";
  const production = productionMatch ? productionMatch[1] : "Not available";

  useEffect(() => {
    if (score !== "Not available") {
      const existingScores = getAllScores();
      existingScores.push(score);
      localStorage.setItem('score', JSON.stringify(existingScores));
    }
  }, [score]);

  const getAllScores = () => {
    const scores = localStorage.getItem('score');
    return scores ? JSON.parse(scores) : [];
  };


  const savedScores = getAllScores();


  const [apiResponsel, setApiResponsel] = useState("")

  const advancedWordsListMatchl = apiResponsel?.match(/"advanced_words_list":\s*"(.*?)"/);
  const scoreMatchl = apiResponsel?.match(/"score":"(\d+)"/);
  const literacyMatchl = apiResponsel?.match(/"literacy":"(\d+)"/);
  const productionMatchl = apiResponsel?.match(/"production":"(\d+)"/);
  const feedbackMatchl = apiResponsel?.match(/"feedback":\s*"(.*?)"/);

  const advancedWordsListl = advancedWordsListMatchl ? advancedWordsListMatchl[1] : "Not available";
  const scorel = scoreMatchl ? scoreMatchl[1] : "Not available";
  const literacyl = literacyMatchl ? literacyMatchl[1] : "Not available";
  const productionl = productionMatchl ? productionMatchl[1] : "Not available";
  const feedbackl = feedbackMatchl ? feedbackMatchl[1] : "Not available";

  const getAllScoresl = () => {
    const scores = localStorage.getItem('scoresl');
    return scores ? JSON.parse(scores) : [];
  };

  useEffect(() => {
    if (scorel !== "Not available") {
      const existingScores = getAllScoresl();
      existingScores.push(scorel);
      localStorage.setItem('scoresl', JSON.stringify(existingScores));
    }
  }, [scorel]);

  const savedScoresl = getAllScoresl();

  const [apiResponsels, setApiResponsels] = useState("")

  const advancedWordsListMatchls = apiResponsels?.match(/"advanced_words_list":\s*"(.*?)"/);
  const scoreMatchls = apiResponsels?.match(/"score":"(\d+)"/);
  const literacyMatchls = apiResponsels?.match(/"literacy":"(\d+)"/);
  const productionMatchls = apiResponsels?.match(/"production":"(\d+)"/);
  const feedbackMatchls = apiResponsels?.match(/"feedback":\s*"(.*?)"/);

  const advancedWordsListls = advancedWordsListMatchls ? advancedWordsListMatchls[1] : "Not available";
  const scoreh = scoreMatchls ? scoreMatchls[1] : "Not available";
  const literacyh = literacyMatchls ? literacyMatchls[1] : "Not available";
  const productionh = productionMatchls ? productionMatchls[1] : "Not available";
  const feedbackls = feedbackMatchls ? feedbackMatchls[1] : "Not available";

  const getAllScoresh = () => {
    const scores = localStorage.getItem('scoreh');
    return scores ? JSON.parse(scores) : [];
  };

  useEffect(() => {
    if (scoreh !== "Not available") {
      const existingScores = getAllScoresh();
      existingScores.push(scoreh);
      localStorage.setItem('scoreh', JSON.stringify(existingScores));
    }
  }, [scoreh]);

  const savedScoresh = getAllScoresh();

  const [apiResponsess, setApiResponsess] = useState("")

  const advancedWordsListMatchss = apiResponsess?.match(/"advanced_words_list":\s*"(.*?)"/);
  const scoreMatchss = apiResponsess?.match(/"score":"(\d+)"/);
  const literacyMatchss = apiResponsess?.match(/"literacy":"(\d+)"/);
  const productionMatchss = apiResponsess?.match(/"production":"(\d+)"/);
  const feedbackMatchss = apiResponsess?.match(/"feedback":\s*"(.*?)"/);

  const advancedWordsListss = advancedWordsListMatchss ? advancedWordsListMatchss[1] : "Not available";
  const scorex = scoreMatchss ? scoreMatchss[1] : "Not available";
  const literacyx = literacyMatchss ? literacyMatchss[1] : "Not available";
  const productionx = productionMatchss ? productionMatchss[1] : "Not available";
  const feedbackss = feedbackMatchss ? feedbackMatchss[1] : "Not available";

  const getAllScorex = () => {
    const scores = localStorage.getItem('scorex');
    return scores ? JSON.parse(scores) : [];
  };

  const [isLoginOpen, setIsLoginOpen] = useState(true); 

  const toggleForms = () => {
    setIsLoginOpen(!isLoginOpen);
  };



  useEffect(() => {
    if (scorex !== "Not available") {
      const existingScores = getAllScorex();
      existingScores.push(scorex);
      localStorage.setItem('scorex', JSON.stringify(existingScores));
    }
  }, [scorex]);

  const savedScorex = getAllScorex();


  const [apiResponsep, setApiResponsep] = useState("");

  const advancedWordsListMatchp = apiResponsep?.match(/"advanced_words_list":\s*"(.*?)"/);
  const scoreMatchp = apiResponsep?.match(/"score":"(\d+)"/);
  const feedbackMatchp = apiResponsep?.match(/"feedback":\s*"(.*?)"/);

  const advancedWordsListp = advancedWordsListMatchp ? advancedWordsListMatchp[1] : "Not available";
  const scorep = scoreMatchp ? scoreMatchp[1] : "Not available";
  const feedbackp = feedbackMatchp ? feedbackMatchp[1] : "Not available";

  const getAllScorep = () => {
    const scores = localStorage.getItem('scorep');
    return scores ? JSON.parse(scores) : [];
  };

  useEffect(() => {
    if (scorep !== "Not available") {
      const existingScores = getAllScorep();
      existingScores.push(scorep);
      localStorage.setItem('scorep', JSON.stringify(existingScores));
    }
  }, [scorep]);

  const savedScorep = getAllScorep();

  const [tryout, setTryout] = useState(false)



  const [apiResponsew, setApiResponsew] = useState("");

  const advancedWordsListMatchw = apiResponsew?.match(/"advanced_words_list":\s*"(.*?)"/);
  const scoreMatchw = apiResponsew?.match(/"score":"(\d+)"/);
  const feedbackMatchw = apiResponsew?.match(/"feedback":\s*"(.*?)"/);

  const advancedWordsListw = advancedWordsListMatchw ? advancedWordsListMatchw[1] : "Not available";
  const scorew = scoreMatchw ? scoreMatchw[1] : "Not available";
  const feedbackw = feedbackMatchw ? feedbackMatchw[1] : "Not available";

  const getAllScorew = () => {
    const scorew = localStorage.getItem('scorew');
    return scorew ? JSON.parse(scorew) : [];
  };

  useEffect(() => {
    if (scorew !== "Not available") {
      const existingScores = getAllScorew();
      existingScores.push(scorew);
      localStorage.setItem('scorew', JSON.stringify(existingScores));
    }
  }, [scorew]);

  const savedScorew = getAllScorew();

  const [user] = useAuthState(auth);

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);


  useEffect(() => {

    (function (c, l, a, r, i, t, y) {
      c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
      t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", "kmnk6zs4yr");
  }, []);


  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  const handleLogout = () => {
    auth.signOut();
  };

  return (
    <>
      <ScrollToTop/>
      <UserSession/>
      <Header signInWithGoogle={signInWithGoogle} user={user} tryout={tryout} isLoginOpen={isLoginOpen} toggleForms={toggleForms} setTryout={setTryout} toggleModal={toggleModal} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} isOpen={isOpen} />
      <Routes>
        <Route path="/" element={<Home user={user} isAuthenticated={isAuthenticated} publications={publications} />} />
        <Route path="/posts/:id" element={<Posts publications={publications} />} />
        <Route path="/practice" element={<Practice paid={paid} setPaid={setPaid} user={user} toggleModal={toggleModal} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} isOpen={isOpen} />} />
        <Route path="/listenspeak" element={<Listenspeak paid={paid} setPaid={setPaid} user={user} literacy={literacyh} production={productionh} apiResponse={apiResponsels} setApiResponse={setApiResponsels} advancedWordsList={advancedWordsListls} scorel={scoreh} feedback={feedbackls} />} />
        <Route path="/listentype" element={<Listentype paid={paid} setPaid={setPaid} user={user} />} />
        <Route path="/readaloud" element={<Readal paid={paid} setPaid={setPaid} user={user} />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/fillblankspaces" element={<Fillblank />} />
        {user &&
          <Route path="/videorecording" element={<VideoRecording user={user} paid={paid} setPaid={setPaid} />} />
        }
        
        {user &&
          <Route path="/profile" element={<Profile user={user} scorep={savedScorep} scoresss={savedScorex} scoresls={savedScoresh} scoresl={savedScoresl} scores={savedScores} />} />
        }

        <Route path="/pricing" element={<Pricing paid={paid} initialOptions={initialOptions} setPaid={setPaid} user={user} />} />
        <Route path="/readselect" element={<Readselect />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/deleteaccount" element={<DeleteAccount/>} />
        <Route path="/readspeak" element={<Readspeak paid={paid} setPaid={setPaid} user={user} literacy={literacyl} production={productionl} apiResponse={apiResponsel} setApiResponse={setApiResponsel} advancedWordsList={advancedWordsListl} scorel={scorel} feedback={feedbackl} />} />
        <Route path="/readwrite" element={<Read_Write paid={paid} setPaid={setPaid} user={user} literacy={literacy} production={production} advancedWordsList={advancedWordsList} score={score} feedback={feedback} apiResponse={apiResponse} setApiResponse={setApiResponse} />} />
        <Route path="/speakingsample" element={<Speaking paid={paid} setPaid={setPaid} user={user} literacy={literacyx} production={productionx} apiResponse={apiResponsess} setApiResponse={setApiResponsess} advancedWordsList={advancedWordsListss} score={scorex} feedback={feedbackss} />} />
        <Route path="/speakphoto" element={<Speakphoto paid={paid} setPaid={setPaid} user={user} apiResponse={apiResponsep} setApiResponse={setApiResponsep} advancedWordsList={advancedWordsListp} score={scorep} feedback={feedbackp} />} />
        <Route path="/writephoto" element={<Writephoto apiResponse={apiResponsew} setApiResponse={setApiResponsew} advancedWordsList={advancedWordsListw} score={scorew} feedback={feedbackw} />} />
        <Route path="/duolingo.test" element={<Det paid={paid} setPaid={setPaid} user={user} toggleModal={toggleModal} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} isOpen={isOpen} />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/speakinglabnew" element={<SpeakingLab />} />
        {user &&
          <Route path="/test" element={<Test currentSection={currentSection} handleNextSection={handleNextSection} sections={sections} />} />
        }
      </Routes>
      <Footer/>
    </>
  );
}

export default App;