import React, { useState, useEffect, useRef } from "react"
import { RiSpeakLine } from "react-icons/ri";
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import data from "../data/data_practice/listenspeak.json"
import { Configuration, OpenAIApi } from "azure-openai";

const openai = new OpenAIApi(
    new Configuration({
        azure: {
            apiKey: "c0086bc08dc64d3b8d8c89a710000e6e",
            endpoint: "https://ysis.openai.azure.com/",
            deploymentName: "luia",
        }
    }),
);

const Listenspeak = ({ production, paid, setPaid, user, literacy, setApiResponse, advancedWordsList, scorel, feedback }) => {

    const audioRef = useRef(null);
    const [aiAnalysisCount, setAiAnalysisCount] = useState(0);

    useEffect(() => {
        const fetchUserData = async () => {
            if (user) {
                const db = getFirestore();
                const userRef = doc(db, 'paying_users', user.email);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setPaid(userDoc.data().paid || false);
                    setAiAnalysisCount(userDoc.data().aiAnalysisCount || 0);
                }
            }
        };

        fetchUserData();
    }, [user]);

    const handleFunctionality = () => {
        if (user && paid) {
            return true;
        } else if (!paid && aiAnalysisCount < 3 && current < 3) {
            return true;
        } else {
            alert("Subscribe to get unlimited access");
            return false;
        }
    };

    const playAudio = () => {
        if (audioRef.current) {
            audioRef.current.play();
        }
    };
    const [timer, setTimer] = useState(240);
    const [analysis, setAnalysis] = useState(false)
    const [showAnswer, setShowAnswer] = useState(false);
    const [listenSpeak, setListenSpeak] = useState("")
    const [current, setCurrent] = useState(0);
    const [isRecording, setIsRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const audio2Ref = useRef(new Audio());


    const startRecording = () => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                const recorder = new MediaRecorder(stream);
                setMediaRecorder(recorder);
                recorder.start();
                setIsRecording(true);

                recorder.ondataavailable = (event) => {
                    const blob = event.data;
                    sendAudio(blob);
                    audio2Ref.current.src = URL.createObjectURL(blob);
                };
            })
            .catch(err => console.error("Error accessing audio:", err));
    };

    const [loadAudio, setLoadAudio] = useState(false)

    const sendAudio = (blob) => {
        setLoadAudio(true)
        const formData = new FormData();
        formData.append('file', blob, 'recording.wav');

        const fetchOptions = {
            method: 'POST',
            headers: {
                'api-key': 'dcb35510a0124e9ca4043d77550bb369',
            },
            body: formData
        };

        
        fetch('https://hermes.openai.azure.com/openai/deployments/apolo/audio/transcriptions?api-version=2023-09-01-preview&language=en-US', fetchOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data.text);
                setListenSpeak(data.text)
                setLoadAudio(false)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
    const [loadingr, setLoadingr] = useState(false)
    const [loadingf, setLoadingf] = useState(false)

    const [loading, setLoading] = useState(false)
    const improvedversion = `This is a sample full Duolingo English Test writing section. This is the prompt: ${data.data[current].prompt} and this is the user answer: ${listenSpeak}. Write an improved version of the user answer}`

    const vocabularyfeedback = `This is a sample full Duolingo English Test writing section. This is the prompt: ${data.data[current].prompt} and this is the user answer: ${listenSpeak}. Write feedback on the user response´s vocabulary}`


    const [voc, setVoc] = useState("")
    const [main, setMain] = useState(false)
    const [second, setSecond] = useState(false)
    const [third, setThird] = useState(false)

    const vocabulary = async () => {
        setLoadingf(true)
        const response = await openai.createChatCompletion({
            "messages": [
                { "role": "system", "content": "You assess the vocabulary of a text" },
                { "role": "user", "content": `${vocabularyfeedback}` }
            ],
            "temperature": 0
        });
        setVoc(response.data.choices[0].message.content)
        setLoadingf(false)
        setThird(true)
    }


    const [im, setIm] = useState("")

    const improve = async () => {
        setLoadingr(true)
        const response = await openai.createChatCompletion({
            "messages": [
                { "role": "system", "content": "You write improved texts" },
                { "role": "user", "content": `${improvedversion}` }
            ],
            "temperature": 0
        });
        setIm(response.data.choices[0].message.content)
        setLoadingr(false)
        setSecond(true)
    }


    const stopRecording = () => {
        if (mediaRecorder) {
            mediaRecorder.stop();
            setIsRecording(false);
        }
        Submit()
        sendAudio()
    };

    useEffect(() => {
        setTimer(240);

        const interval = setInterval(() => {
            setTimer(prevTimer => prevTimer > 0 ? prevTimer - 1 : 0);
        }, 1000);

        return () => clearInterval(interval);
    }, [current]);

    const Submit = () => {
        setShowAnswer(true)
    }

    const instrucciones = `This is a sample full Duolingo English Test writing section. Generate the following for the user response: List of advanced words (if any). Create a score for the text bewteen 10 and 160 for the user response. Generate other score for the Literacy level of the answer, from 10 to 160, and another score for the Production skills, from 10 to 160. Finally, generate detailed feedback. This is the prompt: ${data.data[current].text} and this is the user answer: ${listenSpeak}. Use this format {data=["advanced_words_list": "", "score":"", "literacy":"", "production":"", "feedback": ""] Do not be strict because the user has only 4 minutes to answer.}`

    const sendData = async () => {
        setLoading(true)
        const response = await openai.createChatCompletion({
            messages: [
                { "role": "system", "content": "You assess the writing skills of a candidate" },
                { "role": "user", "content": `${instrucciones}` }
            ]
        });
        console.log(response.data.choices[0].message.content);
        setApiResponse(response.data.choices[0].message.content)
        setShowAnswer(true)
        setMain(true)
        setLoading(false)
    }

    const ver = async () => {
        if (handleFunctionality()) {
            setAnalysis(true);
            sendData();
            const db = getFirestore();
            const userRef = doc(db, 'paying_users', user.email);
            await setDoc(userRef, { aiAnalysisCount: aiAnalysisCount + 1 }, { merge: true });
            setAiAnalysisCount(prevCount => prevCount + 1);
        }
    };

    const handleNext = async () => {
        if (current === data.data.length - 1) {
            const db = getFirestore();
            const userRef = doc(db, 'paying_users', user.email);
            await setDoc(userRef, { aiAnalysisCount: 0 }, { merge: true });
            setAiAnalysisCount(0);
        }

        setCurrent(current + 1);
        setTimer(240);
        setIm("")
        setSecond(false)
        setThird(false)
        setVoc("")
        setAnalysis(false)
        setListenSpeak("")
        setShowAnswer(false)
        setAnalysis(false)
    };

    const barWidth = Math.min(100, (scorel / 160) * 100);
    const litWidth = Math.min(100, (literacy / 160) * 100);
    const proWidth = Math.min(100, (production / 160) * 100);

    useEffect(() => {
        setTimer(90);

        const interval = setInterval(() => {
            setTimer(prevTimer => prevTimer > 0 ? prevTimer - 1 : 0);
        }, 1000);

        return () => clearInterval(interval);
    }, [current]);

    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;

    return (
        <>
            <div className="flex justify-center py-20">
                <div className="w-5/6 sm:w-3/5 rounded border border-gray-200 shadow-2xl p-4">
                    <div id="60 py-6 seconds timer here">
                        <button className="px-5 text-lg py-2 rounded border-2 border-blue-800 text-blue-800 font-semibold">
                            {timer > 0 ? `${minutes}:${seconds.toString().padStart(2, '0')}` : "Time's up!"}
                        </button>
                    </div>
                    <div className="p-4">
                        <div className="flex pb-6 justify-center">
                            <p className="text-blue-950 capitalize text-xl pb-12 font-semibold">Speak about the topic for 90 seconds</p>
                        </div>
                        <div className="">
                            <div className="w-full flex justify-center">
                                <div onClick={playAudio} className="p-12 bg-blue-700 cursor-pointer w-12 h-12 flex justify-center items-center hover:bg-blue-600 flex rounded-full">
                                    <audio src={data.data[current].audioPrompt} ref={audioRef} className="hidden" />
                                    <button className="">
                                        <RiSpeakLine className="text-yellow-300 w-8 h-8" />
                                    </button>
                                </div>
                            </div>
                            <div className="flex w-full justify-center">
                                <div className="flex items-center">
                                    <button
                                        onClick={isRecording ? stopRecording : startRecording}
                                        className="bg-blue-800 hover:bg-blue-700 text-yellow-300 mt-12 px-6 py-3 rounded font-semibold">
                                        {isRecording ? 'Stop Recording' : 'Record Now'}
                                    </button>
                                </div>
                            </div>
                        </div>
                        {loadAudio ? <div className="flex justify-center pt-20">
                            <LoadingIcon />
                        </div> : <>
                            {showAnswer &&
                                <>
                                    <div className="flex justify-center">
                                        <div className="w-full rounded border border-gray-200 p-4 my-10">
                                            <p className="text-lg font-semibold text-gray-800 mt-4 mb-2">Your response</p>
                                            <p className="text-gray-500 mb-4">{listenSpeak}</p>
                                        </div>
                                    </div>
                                    <div className="flex justify-center mb-12">
                                        <button
                                            onClick={ver}
                                            disabled={isRecording}
                                            className={`bg-blue-800 hover:bg-blue-700 rounded text-yellow-300 px-5 py-2 font-semibold mt-4 ${isRecording ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        >
                                            Get AI analysis
                                        </button>
                                    </div>
                                </>
                            }
                        </>}
                        {loading ? <div className="flex justify-center pt-20">
                            <LoadingIcon />
                        </div> :
                            <>
                                {analysis &&
                                    <>
                                        <div className="flex justify-center">
                                            <div className="w-full rounded border shadow-lg border-gray-200 p-4 my-10">
                                                <p className="flex text-gray-500 mt-4 font-medium">Estimated score:
                                                </p>
                                                <div>
                                                    <p className="text-gray-800 font-medium mt-3 mb-7 text-lg">
                                                        {scorel}/160
                                                    </p>
                                                    <div className="bg-gray-200 h-3 w-full rounded">
                                                        <div
                                                            className="bg-blue-500 mb-6 h-3 rounded"
                                                            style={{ width: `${barWidth}%` }}
                                                        ></div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="text-gray-800 font-medium mt-7 mb-3">
                                                        Literacy score: {literacy}/160
                                                    </p>
                                                    <div className="bg-gray-200 h-3 w-full rounded">
                                                        <div
                                                            className="bg-green-500 mb-6 h-3 rounded"
                                                            style={{ width: `${litWidth}%` }}
                                                        ></div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="text-gray-800 font-medium mt-7 mb-3">
                                                        Production score: {production}/160
                                                    </p>
                                                    <div className="bg-gray-200 h-3 w-full rounded">
                                                        <div
                                                            className="bg-green-500 mb-6 h-3 rounded"
                                                            style={{ width: `${proWidth}%` }}
                                                        ></div>
                                                    </div>
                                                </div>
                                                <p className="flex text-gray-500 mb-3 mt-12 font-medium">Advanced Words List:
                                                </p>
                                                <p className="text-gray-800 mb-7 font-medium">{advancedWordsList}</p>
                                                <p className="text-gray-500 mb-3 font-medium">Feedback:
                                                </p>
                                                <p className="text-gray-600">
                                                    {feedback}
                                                </p>
                                                <div className="flex pb-12 justify-evenly pt-12">
                                                    <button onClick={improve} className="bg-blue-800 hover:bg-blue-700 rounded text-yellow-300 px-5 py-2 font-semibold">See improved version</button>
                                                    <button onClick={vocabulary} className="bg-blue-800 hover:bg-blue-700 rounded text-yellow-300 px-5 py-2 font-semibold">Vocabulary feedback</button>
                                                </div>
                                                <div>
                                                    {loadingr ?
                                                        <div className="flex justify-center pt-12">
                                                            <LoadingIcon />
                                                        </div> :
                                                        <div>{second && <p className="text-lg font-semibold text-gray-800 mb-3 mt-6">Improved version</p>}
                                                            <p className="text-gray-600">
                                                                {im}
                                                            </p>
                                                        </div>
                                                    }
                                                    {loadingf ?
                                                        <div className="flex justify-center pt-12">
                                                            <LoadingIcon />
                                                        </div> :
                                                        <div>
                                                            {third && <p className="text-lg font-semibold text-gray-800 mb-3 mt-6">Vocabulary feedback</p>}
                                                            <p className="text-gray-600">
                                                                {voc}
                                                            </p>
                                                        </div>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                            </>}
                        <div className="flex mt-4 justify-evenly pt-6 sm:justify-end">
                            <button
                                onClick={handleNext}
                                className="bg-blue-800 hover:bg-blue-700 rounded text-yellow-300 px-5 py-2 font-semibold mt-4"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Listenspeak;


const LoadingIcon = () => {
    return (
        <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
    );
};
