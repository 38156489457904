import React from "react"
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';


const Report = ({ scoresl, user, scorep, scoresss, scores, scoresls }) => {

    const labels = scores.map((_, index) => (index + 1).toString());
    const labelsl = scoresl.map((_, index) => (index + 1).toString());
    const labelsls = scoresls.map((_, index) => (index + 1).toString());
    const labelsss = scoresss.map((_, index) => (index + 1).toString());
    const labelp = scorep.map((_, index) => (index + 1).toString());

    const datasss = {
        labels: labelsss,
        datasets: [
            {
                label: 'Scores',
                data: scoresss,
                backgroundColor: [
                    'rgba(0, 165, 0, 1)',
                    'rgba(54, 162, 235, 0.2)',
                ],
                borderColor: [
                    'rgba(0, 205, 0, 1)',
                    'rgba(54, 162, 235, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const data = {
        responsive: true,
        labels: labels,
        datasets: [
            {
                label: 'Scores',
                data: scores,
                backgroundColor: [
                    'rgba(0, 165, 0, 1)',
                    'rgba(54, 162, 235, 0.2)',
                ],
                borderColor: [
                    'rgba(0, 205, 0, 1)',
                    'rgba(54, 162, 235, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const datap = {
        labels: labelp,
        datasets: [
            {
                label: 'Scores',
                data: scorep,
                backgroundColor: [
                    'rgba(0, 165, 0, 1)',
                    'rgba(54, 162, 235, 0.2)',
                ],
                borderColor: [
                    'rgba(0, 205, 0, 1)',
                    'rgba(54, 162, 235, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const datals = {
        labels: labelsls,
        datasets: [
            {
                label: 'Scores',
                data: scoresls,
                backgroundColor: [
                    'rgba(0, 165, 0, 1)',
                    'rgba(54, 162, 235, 0.2)',
                ],
                borderColor: [
                    'rgba(0, 205, 0, 1)',
                    'rgba(54, 162, 235, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const datal = {
        labels: labelsl,
        datasets: [
            {
                label: 'Scores',
                data: scoresl,
                backgroundColor: [
                    'rgba(0, 165, 0, 1)',
                    'rgba(54, 162, 235, 0.2)',
                ],
                borderColor: [
                    'rgba(0, 205, 0, 1)',
                    'rgba(54, 162, 235, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    return(
        <>
            <div className="flex justify-center">
            </div>
            <div className="flex justify-center">
<div className="w-4/5 flex justify-center">
<p className="text-2xl font-semibold text-blue-600 uppercase pt-12 pb-12">Your statistics</p>
</div>
            </div>
            <div className="flex pb-20 justify-center">
                <div className="w-11/12 sm:w-4/5 grid grid-cols-1 sm:grid-cols-2">
                <div className="flex justify-center">
                    <div className="w-4/5">
                            <div className="flex justify-center">
                                <p className="text-purple-600 text-lg uppercase font-semibold py-12">Read then write</p>
                            </div>
                       <div className="flex justify-center">
                                <Line
                                    height={160}
                                    options={{ maintainAspectRatio: false }} data={data} />
                       </div>
                    </div>
                    </div>
                    <div className="flex justify-center">
                        <div className="w-4/5">
                            <div className="flex justify-center">
                                <p className="text-purple-600 text-lg uppercase font-semibold py-12">Listen then speak</p>
                            </div>
                            <div className="flex justify-center">
                                <Line
                                    height={160}
                                    options={{ maintainAspectRatio: false }}
                                data={datals} />
                            </div>
                        </div>
                    </div>
            </div>
            </div>
            <div className="flex pb-12 justify-center">
                <div className="w-11/12 sm:w-4/5 grid grid-cols-1 sm:grid-cols-2">
                    <div className="flex justify-center">
                        <div className="w-4/5">
                            <div className="flex justify-center">
                                <p className="text-purple-600 text-lg uppercase font-semibold py-12">Read then speak</p>
                            </div>
                            <div className="flex justify-center">
                                <Line
                                    height={160}
                                    options={{ maintainAspectRatio: false }}
                                    data={datal}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <div className="w-4/5">
                            <div className="flex justify-center">
                                <p className="text-purple-600 text-lg uppercase font-semibold py-12">Speaking sample</p>
                            </div>
                            <div className="flex justify-center">
                                <Line
                                    height={160}
                                    options={{ maintainAspectRatio: false }}
                                data={datasss} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex pb-12 justify-center">
                <div className="w-11/12 sm:w-4/5 grid grid-cols-1 sm:grid-cols-2">
                    <div className="flex justify-center">
                        <div className="w-4/5">
                            <div className="flex justify-center">
                                <p className="text-purple-600 text-lg uppercase font-semibold py-12">Speak about the photo</p>
                            </div>
                            <div className="flex justify-center">
                                <Line
                                    height={160}
                                    options={{ maintainAspectRatio: false }} data={datap} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Report;