import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyC-JWI8bIM7XZmvQkQsOGZ8N29kWiEmnKw",
    authDomain: "pp-edux.firebaseapp.com",
    databaseURL: "https://pp-edux-default-rtdb.firebaseio.com",
    projectId: "pp-edux",
    storageBucket: "pp-edux.appspot.com",
    messagingSenderId: "802243360037",
    appId: "1:802243360037:web:3a61f1a7f57999ec3a16cc",
    measurementId: "G-ZJZTM7TZPL"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const googleProvider = new GoogleAuthProvider();