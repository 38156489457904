import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../firebase';
import { collection, doc, getDoc, setDoc, updateDoc, serverTimestamp } from 'firebase/firestore';

const Login = ({ show, setIsAuthenticated, setShow }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [continent, setContinent] = useState('');

    const candidatesRef = collection(db, "candidates");
    const [trySign, setTrySign] = useState(true);

    const handleSignup = async (e) => {
        e.preventDefault();
        if (!email || !password || !name || !continent) {
            alert("All fields are mandatory.");
            return;
        }
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            const docRef = doc(candidatesRef, user.uid);
            await setDoc(docRef, {
                name: name,
                continent: continent,
            });
            setTrySign(false);
            console.log('User signed up and data stored:', user.email);
        } catch (error) {
            console.error('Error signing up:', error);
        }
    };

    const handleSignin = async (e) => {
        e.preventDefault();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            setIsAuthenticated(true);
            setShow(false);
            await startSession(user.uid);
            console.log('User signed in:', user.email);
        } catch (error) {
            console.error('Error signing in:', error);
        }
    };

    const startSession = async (userId) => {
        const sessionRef = doc(db, 'sessions', userId);
        await setDoc(sessionRef, {
            startTime: serverTimestamp(),
        });
    };

    const endSession = async () => {
        const user = auth.currentUser;
        if (user) {
            const sessionRef = doc(db, 'sessions', user.uid);
            await updateDoc(sessionRef, {
                endTime: serverTimestamp(),
            });
        }
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                await startSession(user.uid);
            }
        });

        window.addEventListener('beforeunload', endSession);

        return () => {
            unsubscribe();
            window.removeEventListener('beforeunload', endSession);
        };
    }, []); 

    return (
        <>
            {show &&
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    {trySign?
                        <div className="bg-white p-8 rounded shadow-lg">
                            <h2 className="text-2xl text-center px-32 font-bold ">Sign Up</h2>
                            <div className='flex justify-center'>
                                <button onClick={() => setTrySign(false)} className='text-center text-sm text-blue-500 py-4 cursor-pointer hover:text-blue-700'>Login instead</button>
                            </div>
                            <form onSubmit={handleSignup}>
                                <div className="mb-4">
                                    <label htmlFor="email" className="block mb-1">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="w-full px-3 py-2 outline-none border border-gray-300 rounded"
                                        required
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="password" className="block mb-1">
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className="w-full px-3 outline-none py-2 border border-gray-300 rounded"
                                        required
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="name" className="block mb-1">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        id="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className="w-full outline-none px-3 py-2 border border-gray-300 rounded"
                                        required
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="continent" className="block mb-1">
                                        Continent
                                    </label>
                                    <select
                                        id="continent"
                                        value={continent}
                                        onChange={(e) => setContinent(e.target.value)}
                                        className="w-full px-3 outline-none py-2 border border-gray-300 rounded"
                                        required
                                    >
                                        <option value="" disabled>Select one continent</option>
                                        <option value="Africa">Africa</option>
                                        <option value="Asia">Asia</option>
                                        <option value="Europe">Europe</option>
                                        <option value="North America">North America</option>
                                        <option value="Oceania">Oceania</option>
                                        <option value="South America">South America</option>
                                    </select>
                                </div>
                                <div className='flex justify-center pb-6'>
                                    <button
                                        type="submit"
                                        className="bg-blue-500 text-white px-4 py-2 rounded"
                                    >
                                        Sign Up
                                    </button>
                                </div>
                                <div className='flex py-6 justify-end'>
                                    <button
                                        onClick={() => setShow(false)}
                                        type="button"
                                        className="bg-red-500 text-white px-4 py-2 rounded"
                                    >
                                        Close
                                    </button>
                                </div>
                            </form>
                        </div>
                        :
                        <div className="bg-white p-8 rounded shadow-lg">
                            <h2 className="text-2xl text-center px-32 font-bold ">Sign in</h2>
                            <div className='flex justify-center'>
                                <button onClick={() => setTrySign(true)} className='text-center text-sm text-blue-500 py-4 cursor-pointer hover:text-blue-700'>Sign up instead</button>
                            </div>
                            <form onSubmit={handleSignin}>
                                <div className="mb-4">
                                    <label htmlFor="email" className="block mb-1">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="w-full px-3 py-2 outline-none border border-gray-300 rounded"
                                        required
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="password" className="block mb-1">
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className="w-full px-3 outline-none py-2 border border-gray-300 rounded"
                                        required
                                    />
                                </div>
                                <div className='flex justify-center pb-6'>
                                    <button
                                        type="submit"
                                        className="bg-blue-500 text-white px-4 py-2 rounded"
                                    >
                                        Sign in
                                    </button>
                                </div>
                                <div className='flex py-6 justify-end'>
                                    <button
                                        onClick={() => setShow(false)}
                                        type="button"
                                        className="bg-red-500 text-white px-4 py-2 rounded"
                                    >
                                        Close
                                    </button>
                                </div>
                            </form>
                        </div>
                    }
                </div>
            }
        </>
    );
};

export default Login;
