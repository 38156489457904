import React, { useState } from 'react';
import { Trash2 } from 'lucide-react';
import { initializeApp } from "firebase/app";
import { getAuth, deleteUser } from "firebase/auth";
import { getFirestore, collection, addDoc, query, where, getDocs, deleteDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyC-JWI8bIM7XZmvQkQsOGZ8N29kWiEmnKw",
    authDomain: "pp-edux.firebaseapp.com",
    databaseURL: "https://pp-edux-default-rtdb.firebaseio.com",
    projectId: "pp-edux",
    storageBucket: "pp-edux.appspot.com",
    messagingSenderId: "802243360037",
    appId: "1:802243360037:web:3a61f1a7f57999ec3a16cc",
    measurementId: "G-ZJZTM7TZPL"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

const DeleteAccount = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
    });
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        alert("Your information is being deleted")
    };

    return (
        <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">Delete Account</h2>
            <p className="mb-4 text-gray-600">
                To delete your account, please provide your name and email address below.
            </p>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        className="mt-1 block w-full p-2 outline-none rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                </div>
                <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        className="mt-1 block w-full p-2 outline-none rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                </div>
                <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                    Delete Account
                    <Trash2 className="ml-2 h-5 w-5" />
                </button>
            </form>
            {isSubmitted && (
                <p className="mt-4 text-green-600">Your account has been deleted successfully.</p>
            )}
        </div>
    );
};

export default DeleteAccount;
