import React, {useState, useEffect} from "react";
import { ArrowCircleRightIcon } from "@heroicons/react/outline";
import {Link} from "react-router-dom";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { auth, googleProvider } from ".././firebase";
import { signInWithPopup } from 'firebase/auth';
import Athena from "./athena"
import Login from "./login";
import Leaderboard from "./leaderboard";

const Home = ({ publications, user, isAuthenticated }) => {

    const signInWithGoogle = async () => {
        try {
            await signInWithPopup(auth, googleProvider);
        } catch (error) {
            console.error("Error signing in with Google:", error);
        }
    };

    const handleLogout = () => {
        auth.signOut();
    };

    const percentage = 91; 
    const sortedPublications = [...(publications ?? [])].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

    const [postCount, setPostCount] = useState(3)

    const showMore = () => {
        setPostCount(postCount+3)
    }

    const [buttons, setButtons] = useState(true)

    useEffect(() => {
        if (postCount > publications.length) {
            setButtons(false);
        } else {
            setButtons(true);
        }
    }, [postCount, publications.length]);

    return(
        <>
            <div className="">
                <div className="flex justify-center">
                    <div className="w-full flex items-center bg-cover justify-center pt-8 h-screen bg-[#060c3d]">
                        <div className="px-6 sm:px-2">
                            <div className="flex justify-center">
                                <p className="text-white font-semibold text-3xl">Full Duolingo English test powered by GPT-4</p>
                            </div>
                            <div className="flex justify-center">
                                <p className="text-white font-semibold text-lg pt-6">Scores, feedback, profile report, and many more</p>
                            </div>
                            <div className="flex sm:hidden px-24 pt-12 items-center justify-center">
                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.edux.quizui" rel="noopener noreferrer">
                                    <img src="https://luya.blob.core.windows.net/edux/logoandroid.png" className="w-32 h-auto">
                                    </img>
                            </a>
                            </div>
                            <div className="hidden sm:flex px-24 pt-12 items-center justify-center">
                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.edux.quizui" rel="noopener noreferrer">
                                    <img src="https://luya.blob.core.windows.net/edux/logoandroid.png" className="w-32 h-auto cursor-pointer">
                                    </img>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex py-24 justify-center">
                    <div className="grid grid-cols-1 sm:grid-cols-2 w-5/6">
                    <div className="flex mt-16 items-center justify-center">
                            <div>
                               <div>
                                    <img className="w-11/12 rounded-xl h-auto 2xl:h-96" src="https://luya.blob.core.windows.net/edux/fordet.jpg" />
                               </div>
                               <div className="flex pt-6 justify-center">
                                    {user ? (
                                        <>
                                            <Link to="/videorecording" className="px-5 py-2 border-2 border-blue-600 font-semibold rounded bg-blue-600 text-white hover:bg-blue-500 rounded">
                                                Turn your camera on!
                                            </Link>
                                        </>
                                    ) : (
                                            <button onClick={signInWithGoogle} className="px-5 py-2 border-2 border-blue-600 font-semibold rounded bg-blue-600 text-white hover:bg-blue-500 rounded">
                                                Turn your camera on!
                                            </button>
                                    )}
                               </div>
                            </div>
                        </div>
                        <div className="mt-16">
                        <div className="flex justify-center">
                                <div className='w-full pt-6 px-4'>
                                    <p className='text-blue-600 text-2xl text-center font-semibold pb-3'>Record yourself in video!</p>
                                    <div className='flex justify-center'>
                                <div className="w-1/4 pt-4">
                                            <CircularProgressbar
                                                value={percentage}
                                                maxValue={100}
                                                text={`${percentage}%`}
                                                styles={{
                                                    path: {
                                                        stroke: '#00bf99',
                                                        strokeLinecap: 'butt',
                                                        transition: 'stroke-dashoffset 0.5s ease 0s',
                                                    },
                                                    trail: {
                                                        stroke: '#eee',
                                                    },
                                                    text: {
                                                        fill: '#000',
                                                        fontSize: '13px',
                                                    },
                                                }}
                                            />
                                    </div>
                                    </div>
                                    <div class='w-full flex justify-center py-5 flex items-center space-x-3'>
                                        <p className="text-sm"><div class="inline-block inline-flex w-4 mx-1 h-4 bg-red-700"></div>0 ~ 59</p>
                                        <p className="text-sm"><div class="inline-block mx-1 w-4 h-4 bg-yellow-700"></div>60 ~ 79</p>
                                        <p className="text-sm"><div class="inline-block w-4 h-4 mx-1" style={{ backgroundColor: '#00bf99' }}></div>80 ~ 100</p>
                                    </div>
                                </div>
                                </div>
                                <div className='2xl:px-12'>
                                    <div className='px-2'>
                                        <div className='flex w-full justify-between'>
                                            <p className='text-gray-600'>Fluency Score: </p>
                                            <p className='text-gray-600'>96/100</p>
                                        </div>
                                    <div style={{ width: '100%', backgroundColor: '#eee', height: '15px' }}>
                                        <div className='rounded' style={{ width: `96%`, backgroundColor: 'blue', height: '100%' }}></div>
                                    </div>
                                        <div className='flex w-full justify-between'>
                                            <p className='text-gray-600 pt-4'>Prosody Score: </p>
                                            <p className='text-gray-600 pt-4'>90/100</p>
                                        </div>
                                        <div style={{ width: '100%', backgroundColor: '#eee', height: '15px' }}>
                                            <div className='rounded' style={{ width: `90%`, backgroundColor: 'blue', height: '100%' }}></div>
                                        </div>
                                    </div>
                                    <div className='px-2'>
                                        <div className='flex w-full justify-between'>
                                            <p className='text-gray-600 pt-4'>Completeness Score: </p>
                                            <p className='text-gray-600 pt-4'>94/100</p>
                                        </div>
                                        <div style={{ width: '100%', backgroundColor: '#eee', height: '15px' }}>
                                            <div className='rounded' style={{ width: `94%`, backgroundColor: 'blue', height: '100%' }}></div>
                                        </div>
                                        <div className='flex w-full justify-between'>
                                            <p className='text-gray-600 pt-4'>Accuracy Score: </p>
                                            <p className='text-gray-600 pt-4'>86/100</p>
                                        </div>
                                    <div style={{ width: '100%', backgroundColor: '#eee', height: '15px' }}>
                                        <div className='rounded' style={{ width: `86%`, backgroundColor: 'blue', height: '100%' }}></div>
                                    </div>
                                    </div>
                                </div>                    
                    </div>
                    </div>
                </div>
                <Athena/>
                <div className="flex justify-center">
                    <div className="w-5/6 grid grid-cols-1">
                        <div>
                            <p className="pt-20 pb-4 text-blue-600 font-semibold text-2xl pl-6">Latest blogs</p>
                            <p className="pb-12 text-gray-400 font-semibold text-lg pl-6">Read what´s coming next for LuIA and our thoughts on the current AI economic transformation the world is currently undergoing</p>
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className="flex justify-center">
                        <div className="w-11/12 2xl:w-5/6 pt-6 pb-20 grid grid-cols-1 sm:grid-cols-3">
                            {(sortedPublications ?? []).slice(0, postCount).map((publication, index) => {
                                return (
                                    <Link to={`/posts/${publication.id}`}><div key={index} className="flex sm:my-8 justify-center">
                                        <div className="w-5/6 2xl:w-3/4 border my-6 sm:my-0 cursor-pointer hover:border-blue-600 hover:border-2 border-gray-300 rounded-lg">
                                            <img className="w-full sm:w-full h-auto sm:h-48 bg-cover h-auto rounded-t-lg" src={publication.image} />
                                            <div className="p-6">
                                                <p className="text-gray-600 text-xs font-semibold pb-3">BLOG</p>
                                                <p className="text-gray-700 text-lg font-semibold pb-3">{publication.title}</p>
                                                <p className="text-gray-500 text-justify">{publication.Description}</p>
                                            </div>
                                            <div className="grid px-6 pb-6 grid-cols-2">
                                                <div></div>
                                                <div className="flex justify-end">
                                                    <ArrowCircleRightIcon className="w-6 h-6 cursor-pointer text-blue-500 hover:text-blue-700" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </Link>
                                );
                            })}
                        </div>
                    </div>
                    {buttons &&
                        <div className="flex justify-center pb-16">
                            <button onClick={showMore} className="px-5 py-2 rounded bg-blue-200 text-blue-700 font-semibold hover:bg-blue-300">Show more</button>
                        </div>
                    }
                </div>
                <div className="flex justify-center">
                    <div className="w-full sm:w-5/6 flex items-center justify-center pt-8 h-screen bg-[url('https://luya.blob.core.windows.net/edux/secondbg.png')]">
                        <div className="px-6 sm:px-2">
                            <div className="flex justify-center">
                                <p className="text-gray-800 font-semibold text-3xl">Duolingo English full Test</p>
                            </div>
                            <div className="flex justify-center">
                                <p className="text-gray-800 font-semibold text-lg pt-6">Get real time feedback and scores generated by AI, GPT-4 turbo and Whisper AI.</p>
                            </div>
                            <div className="flex justify-center">
                                <Link to="/duolingo.test"><button className="px-5 py-2 hover:bg-gray-200 rounded-xl border-2 mt-12 font-bold border-blue-600 text-blue-600 bg-transparent">Take it now</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;