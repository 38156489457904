import {useState, useEffect} from "react"

const Result = ({ detResult, showScores, loadingResult, assesDET }) => {

    const regex = /"(\w+)":"(\d+)"|"feedback":"([\s\S]*?)"/g;
    const extractedData = {};
    let match;
    while ((match = regex.exec(detResult)) !== null) {
        if (match[1] && match[2]) {
            extractedData[match[1]] = match[2];
        } else if (match[3]) {
            extractedData['feedback'] = match[3];
        }
    }
    
    return(
        <>
                    <div className="flex justify-center py-16">
                        <button onClick={assesDET} className="px-5 py-3 bg-blue-700 text-yellow-300 font-semibold rounded hover:bg-blue-600">Click to get your results</button>
                    </div>
            {loadingResult? <div className="flex justify-center">
<p className="py-20 font-semibold text-gray-600">Loading results...</p>
            </div> : 
<>
                    {showScores &&
                        <div className="flex justify-center">
                            <div className="w-5/6 sm:w-3/4 pb-24">
                                <ScoreBars scores={extractedData} />
                            </div>
                        </div>
                    }
</>
            }
        </>
    )
}

const ScoreBars = ({ scores }) => {
    return (
        <div className="space-y-4">
            <div className="text-start pt-16 text-lg font-semibold">
                <p className="pb-3 text-gray-700">Overall score: {scores.global_score}/160</p>
            </div>
            {Object.keys(scores).map((key) => {
                if (key !== 'global_score' && key !== 'feedback') {
                    return (
                        <div key={key} className="flex items-center">
                            <div className="w-1/3">
                                <p className="text-gray-500">{key}: {`${scores[key]}/160`}</p>
                            </div>
                            <div className="w-2/3 bg-gray-200 rounded-full h-4">
                                <div
                                    className="bg-blue-600 h-4 rounded-full"
                                    style={{ width: `${(scores[key] / 160) * 100}%` }}
                                ></div>
                            </div>
                        </div>
                    );
                }
                return null;
            })}
            <div className="mt-8 flex justify-center">
                <div className="w-full pt-8">
                    <p className="text-justify">{scores.feedback && scores.feedback}</p>
                </div>
            </div>

        </div>
    );
};

export default Result;