import {useState, useEffect} from "react"
import {FaCheckCircle} from "react-icons/fa"
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Checkout from "./checkout";

const Pricing = ({ user, initialOptions, paid, setPaid }) => {

    return(
        <>
            <div className="lg:flex justify-center">
                <div className="w-5/6 mx-8 sm:w-1/3 2xl:w-1/4 rounded border border-blue-300 p-6 my-20">
                <p className="text-gray-700 text-lg font-semibold">Buy plan</p>
                <div className="">
                <p className="pt-2 text-blue-600 pb-4 font-semibold">15 USD / Month</p>
                </div>
                    <p className="font-semibold text-lg text-gray-600 py-4">Only one payment forever. It includes:</p>
                    <ul>
                        <li className="my-3 flex items-center"><FaCheckCircle className="w-5 mr-1 h-5 text-green-500" /> <p className="font-semibold">Unlimited practice questions</p> </li>
                        <li className="my-3 flex items-center"><FaCheckCircle className="w-5 mr-1 h-5 text-green-500" /> <p className="font-semibold">Sample full DET with real time scores</p> </li>
                        <li className="my-3 flex items-center"><FaCheckCircle className="w-5 mr-1 h-5 text-green-500" /> <p className="font-semibold">Unlimited AI scoring on your writing skills</p> </li>
                        <li className="my-3 flex items-center"><FaCheckCircle className="w-5 mr-1 h-5 text-green-500" /> <p className="font-semibold">Unlimited AI scoring on your speaking skills</p> </li>
                        <li className="my-3 flex items-center"><FaCheckCircle className="w-5 mr-1 h-5 text-green-500" /> <p className="font-semibold">Unlimited AI graded video recordings</p> </li>
                        <li className="my-3 flex items-center"><FaCheckCircle className="w-5 mr-1 h-5 text-green-500" /> <p className="font-semibold">Unlimited access to tutorial videos</p> </li>

                    </ul>
                </div>
                <PayPalScriptProvider className="sm:w-full 2xl:w-1/4" options={initialOptions}>
                    <Checkout classNamew=" 2xl:w-full" setPaid={setPaid} />
                </PayPalScriptProvider>

            </div>
        </>
    )
}

export default Pricing;