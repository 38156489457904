import React, { useState } from 'react';

const WritingSample = ({ data, handleNextSection, setWriteSample  }) => {
    const [current, setCurrent] = useState(0);

    const [currentResponse, setCurrentResponse] = useState('');

    const handleNext = () => {
        if (current < data.length - 1) {
            setWriteSample(prevResponses => [...prevResponses, currentResponse]);
            setCurrent(current + 1);
            setCurrentResponse('');
        } else {
            setWriteSample(prevResponses => [...prevResponses, currentResponse]);
            handleNextSection();
        }
    };

    return (
        <div className="p-4">
            <div className="flex pb-6 justify-center">
                <p className="text-gray-800 text-lg font-semibold">Writing sample</p>
            </div>
            <p className="mb-4">{data[current].prompt}</p>
            <textarea
                value={currentResponse}
                onChange={(e) => setCurrentResponse(e.target.value)}
                className="rounded p-2 w-full h-40 border font-medium outline-none border-blue-300 text-gray-600"
                placeholder="Your response"
            />
            <div className="flex mt-4 justify-end">
                <button
                    onClick={handleNext}
                    className="bg-blue-800 hover:bg-blue-700 rounded text-yellow-300 px-5 py-2 font-semibold mt-4"
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default WritingSample;